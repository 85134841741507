import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { URL } from '../../service/Service';
import AlertError from './AlertError';
import AlertSuccess from './AlertSuccess';

export const InfoPartner = () => {
  const { id } = useParams();
  const jwtToken = sessionStorage.getItem('jwt');
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [unSuccessUpdate, setUnSuccessUpdate] = useState(false);

  const [data, setData] = useState({
    email: '',
    name: '',
    address: '',
    zipCode: '',
    nif: '',
    notes: '',
    createAt: '',
    url: '',
    rateType: '',
    rateValue: '',
    // softwareType: '', // ignore - not used, but its not to delete - required to create a new affiliate (default value is NA)
    iban: '',
  });

  useEffect(() => {
    fetch(`${URL}/api/affiliates/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((fetchedData) => {
        setData(fetchedData);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [id, jwtToken]);

  useEffect(() => {
    let timeoutId;
    if (successUpdate) {
      timeoutId = setTimeout(() => {
        setSuccessUpdate(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [successUpdate]);

  const updateHandler = (e) => {
    e.preventDefault();
  
    fetch(`${URL}/api/affiliates/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (response.ok) {
        setSuccessUpdate(true);
        setErrorMessage(''); // Clean error messages
        console.log('Afiliado atualizado com sucesso.');
      } else {
        return response.text().then((text) => {
          setUnSuccessUpdate(true);
          setErrorMessage(text);
          console.error('Server error:', text);
        });
      }
    })
    .catch((error) => {
      setUnSuccessUpdate(true);
      let errorMessage = error.message || 'Erro de rede';

      // If the error is due to the network, show a more friendly message
      if (error.message === 'Failed to fetch') {
          errorMessage = 'An issue occurred while updating Affiliate. Please try again later.';
      }

      setErrorMessage(errorMessage);
      console.error('Erro:', errorMessage);
  });
};

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <div className="overflow-hidden px-6 bg-white shadow sm:rounded-md p-4 mb-10">
      <section aria-labelledby="payment-details-heading">
        <form onSubmit={updateHandler}>
          {error && <AlertError message={error} />}
          {unSuccessUpdate && (
            <AlertError message={errorMessage || "There was a problem updating the partner's information. Please check your input and try again."} />
          )}
          {successUpdate && <AlertSuccess message={`Afiliado ${data.name} alterado.`} />}

            <div className=" py-6 px-4 sm:p-6">
              <div>
                <h2 id="payment-details-heading" className="text-lg font-bold leading-6 text-gray-900">
                  Informações/Detalhes
                </h2>
                <p className="mt-2 text-sm text-gray-500">
                  Aqui pode fazer update dos detalhes do afiliado e ver todas as informações sobre o mesmo.
                </p>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label htmlFor="partnerName" className="block text-sm font-medium text-gray-700">
                    Nome
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="partnerName"
                    value={data.name || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    NIF (Número de Identificação Fiscal)
                  </label>
                  <input
                    type="text"
                    name="nif"
                    id="last-name"
                    value={data.nif || ''}
                    disabled
                    className="mt-1 block w-full rounded-md cursor-not-allowed border border-gray-300 bg-gray-200 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={data.email || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                    Website
                  </label>
                      <input
                        type="text"
                        name="url"
                        id="url"
                        autoComplete="url"
                        value={data.url || ''}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 max-w-full shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                      />
                </div>

                <div>
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Morada Completa
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    autoComplete="address"
                    value={data.address || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                    Código Postal
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    id="postal-code"
                    autoComplete="postal-code"
                    value={data.zipCode || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                    Notas
                  </label>
                  <textarea
                    type="text"
                    name="notes"
                    id="notes"
                    autoComplete="cc-given-notes"
                    value={data.notes || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full h-20 resize-none rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div>

                {/*
                IGNORED FIELD - NOT USED (for now)
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Software
                  </label>
                  <input
                    type="text"
                    name="softwareType"
                    id="name"
                    autoComplete="cc-given-name"
                    value={data.softwareType || ''}
                    onChange={handleChange}
                    disabled
                    className="mt-1 block w-full rounded-md cursor-not-allowed border border-gray-300 bg-gray-200 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring focus:ring-gray-900 sm:text-sm"
                  />
                </div> */}
              </div>
            </div>
            <div className=" px-4 py-3 flex justify-end">
              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md bg-green-cl hover:bg-green-900  focus:ring-green-cl focus:ring-2 focus:ring-offset-2 text-white px-4 py-2 text-sm font-medium"
              >
                Atualizar
              </button>
            </div>
           </form>
      </section>
    </div>
  );
};