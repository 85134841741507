import { useEffect, useState } from 'react';
import { URL } from '../service/Service';
function useFetchAffiliate(jwtToken) {
  const [data, setData] = useState({
    email: '',
    name: '',
    address: '',
    zipCode: '',
    nif: '',
    notes: '',
    createAt: '',
    url: '',
    rateType: '',
    rateValue: '',
    softwareType: '',
    iban: '',
    affiliateID: '',
  });

  useEffect(() => {
    if (jwtToken) {
      fetch(`${URL}/api/afil`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error('Network response was not ok');
        })
        .then((fetchedData) => {
          setData(fetchedData);
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  }, [jwtToken]);

  return data;
}

export default useFetchAffiliate;