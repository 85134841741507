import axios from "axios";
import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { URL } from "../../service/Service";

const CreateAdminModal = ({ show, handleClose, userRole, refreshAdmins }) => {
  console.log("CreateAdminModal received userRole:", userRole);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(
    userRole.toUpperCase() === "ADMIN" ? "ADMIN" : "MANAGER"
  );

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Name is mandatory.";
    }
    if (!email.trim()) {
      newErrors.email = "Email is mandatory.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email address.";
    }
    if (!password.trim()) {
      newErrors.password = "Password is mandatory.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        const jwtToken = sessionStorage.getItem("jwt");
        const endpoint = role === "ADMIN" ? "/admin/create" : "/manager/create";

        const response = await axios.post(
          `${URL}/api${endpoint}`,
          { name, email, password, role },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        console.log(
          `${
            role === "ADMIN" ? "Administrator" : "Manager"
          } created successfully:`,
          response.data
        );
        setSuccessMessage(
          `${
            role === "ADMIN" ? "Administrator" : "Manager"
          } created successfully!`
        );

        setTimeout(() => {
          handleClose();
          clearForm();
          refreshAdmins();
        }, 2000);
      } catch (error) {
        console.error(
          `Error creating ${role === "ADMIN" ? "admin" : "manager"}:`,
          error
        );
        if (error.response) {
          // Display the specific backend message if available
          const errorMessage =
            error.response.data.message || error.response.data;

          setErrors({
            form: errorMessage, // Shows the backend message directly
          });

          // Specific handling if the backend has a known message structure
          if (errorMessage.includes("email already in use")) {
            setErrors({ email: "This email is already in use." });
          }
        } else if (error.request) {
          console.error("Request made, no response received");
        } else {
          console.error("Error setting up request:", error.message);
        }
      }
    }
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setPassword("");
    setRole(userRole === "ADMIN" ? "ADMIN" : "MANAGER");
    setErrors({});
    setSuccessMessage("");
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          handleClose();
          clearForm();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Novo Administrador ou Gestor
                </Dialog.Title>
                <div className="mt-4">
                  <form>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Nome
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Insira o nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.name}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Insira o email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Senha
                      </label>
                      <input
                        type="password"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Insira a senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {errors.password && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.password}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Tipo de Conta
                      </label>
                      {userRole === "ADMIN" ? (
                        <select
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={role}
                          onChange={(e) => {
                            console.log("Current userRole:", userRole);
                            console.log(
                              "Attempted to select role:",
                              e.target.value
                            );
                            setRole(e.target.value);
                          }}
                        >
                          <option value="ADMIN">Administrador</option>
                          <option value="MANAGER">Gestor</option>
                        </select>
                      ) : (
                        <div className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm bg-gray-50 sm:text-sm text-gray-700">
                          Gestor
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                {successMessage && (
                  <p className="text-green-600 text-sm mt-2">
                    {successMessage}
                  </p>
                )}
                {errors.form && (
                  <p className="text-red-500 text-sm mt-2">{errors.form}</p>
                )}
                <div className="mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSave}
                  >
                    Salvar
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => {
                      handleClose();
                      clearForm();
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateAdminModal;
