import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import eup from "./eup.png";
import { URL } from "../../service/Service";

export default function ResetPassword() {
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await axios.post(`${URL}/api/resetpassword`, {
        userEmail: userEmail,
      });
      setLoading(false); // Stop loading after the request is successful
      navigate("/");
    } catch (error) {
      setLoading(false); // Stop loading if there's an error
      setError("Ocorreu um erro ao processar sua solicitação.");
    }
  };

  const handleBackToLogin = () => {
    navigate("/");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-green-cl sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="bg-white py-12 px-6 shadow sm:rounded-lg">
          <div className="mx-auto">
            <img className="mx-auto h-16 w-auto" src={eup} alt="Eu pago" />
          </div>

          <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
            {/* Email input */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-black"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border text-black border-green-cl border-opacity-60 px-3 py-2 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900 sm:text-sm"
                  placeholder="Introduza o seu email"
                />
              </div>
            </div>

            {/* Submit and Voltar ao Login buttons */}
            <div className="flex justify-between items-center">
              {/* Submit button */}
              <button
                type="submit"
                disabled={loading} // Disable button when loading
                className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                  loading ? "bg-gray-500" : "bg-green-cl hover:bg-black"
                } focus:outline-none focus:shadow-outline-md`}
              >
                {loading ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin-slow h-5 w-5 text-white mr-2" // Apply custom animation here
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Carregando...
                  </div>
                ) : (
                  "Recuperar Password"
                )}
              </button>

              {/* Voltar ao Login button */}
              <button
                type="button"
                onClick={handleBackToLogin}
                className="flex items-center justify-center ml-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-green-cl bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-4 text-green-cl"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {/* Error message */}
            {error && <div className="text-red-500 text-sm">{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}
