import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUsers,
  faChartLine,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';
import { TableClients } from '../../scenes/clients/TableClients';
import { InfoPartner } from '../../scenes/partner/InfoPartner';
import { RecordsUserAdminPage } from '../../scenes/partner/RecordsUserAdminPage';
import NewRates from '../../scenes/rates/NewRates';
const tabs = [
  {
    name: 'Informações Afiliado',
    icon: faUser,
    content: <InfoPartner />,
  },
  {
    name: 'Clientes',
    icon: faUsers,
    content: <TableClients />,
  },
  {
    name: 'Taxas',
    icon: faChartLine,
    content: <NewRates />,
  },
  {
    name: 'Registos',
    icon: faHistory,
    content: <RecordsUserAdminPage />,
  },
];

function TabsUser() {
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab || tabs[0].name;
  });

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="mt-5 mx-4 lg:mx-10">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-lg border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={activeTab}
          onChange={(e) => handleTabClick(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex lg:space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`w-full py-2 text-center font-medium text-sm lg:text-base focus:outline-none ${
                tab.name === activeTab
                  ? 'border-b-2 font-bold border-green-cl text-green-cl'
                  : 'border-b-2 border-transparent text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => handleTabClick(tab.name)}
            >
              <div className="flex items-center justify-center space-x-2">
                <FontAwesomeIcon icon={tab.icon} className="text-xl" />
                <span>{tab.name}</span>
              </div>
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-5">{tabs.find((tab) => tab.name === activeTab)?.content}</div>
    </div>
  );
}

export default TabsUser;