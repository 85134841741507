/* export const URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://affiliates.eupago.pt'; */
//Test Locahost
//export const URL = "http://localhost:8080";

//Production
export const URL = 'https://affiliates.eupago.pt'

export function makeHttpRequest(url, request, success, failure) {
  let token = sessionStorage.getItem("jwt");
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  request.headers = headers;
  fetch(url, request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return res.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((res) => success(res))
    .catch((err) => failure(err));
}
