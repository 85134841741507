import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import eup from "./eup.png";
import { URL } from "../../service/Service";

export default function ResetPasswordForm() {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordRepeat, setUserPasswordRepeat] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams(); // Get the token parameter from the URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Check if passwords match
    if (userPassword !== userPasswordRepeat) {
      setError("As senhas não coincidem.");
      setLoading(false); // Stop loading on error
      return;
    }

    try {
      const response = await axios.patch(`${URL}/api/users/resetpassword`, {
        email: userEmail,
        newPassword: userPassword,
        token: token,
      });

      if (response.status === 200) {
        navigate("/");
      } else {
        setError("Ocorreu um problema ao redefinir a senha.");
      }
    } catch (error) {
      setError("Ocorreu um erro ao processar sua solicitação.");
    } finally {
      setLoading(false); // Stop loading after the request is done
    }
  };

  return (
    <div className="flex mx-auto min-h-screen h-screen items-center justify-center bg-green-cl sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="bg-white py-12 px-6 shadow sm:rounded-lg">
          <div className="mx-auto">
            <img className="mx-auto h-16 w-auto" src={eup} alt="Eu pago" />
          </div>

          <form className="mt-6 space-y-6" onSubmit={handleSubmit}>
            {/* Email input */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-black"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border text-black border-green-cl border-opacity-60 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900 sm:text-sm"
                  placeholder="Introduza o seu email"
                />
              </div>
            </div>

            {/* Password input */}
            <div className="relative">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-black"
              >
                Nova Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  className="block w-full appearance-none rounded-md border text-black border-green-cl border-opacity-60 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900 sm:text-sm"
                  placeholder="Introduza a nova password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-5 right-2 h-5 w-7 transform -translate-y-1/2 flex items-center justify-center text-green-cl rounded-full"
                  aria-label="Toggle Password Visibility"
                >
                  {showPassword ? (
                    <EyeIcon className="text-green-cl h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="text-green-cl h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {/* Repeat Password input */}
            <div className="relative">
              <label
                htmlFor="passwordRepeat"
                className="block text-sm font-medium text-black"
              >
                Repetir Nova Password
              </label>
              <div className="relative">
                <input
                  id="passwordRepeat"
                  name="passwordRepeat"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  value={userPasswordRepeat}
                  onChange={(e) => setUserPasswordRepeat(e.target.value)}
                  className="block w-full appearance-none rounded-md border text-black border-green-cl border-opacity-60 px-3 py-2 placeholder-gray-400 shadow-sm focus:ring-green-cl focus:outline-none focus:border-green-900 sm:text-sm"
                  placeholder="Repita a nova password"
                />
              </div>
            </div>

            {/* Submit button */}
            <div>
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  disabled={loading} // Disable button while loading
                  className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                    loading ? "bg-gray-500" : "bg-green-cl hover:bg-black"
                  } focus:outline-none focus:shadow-outline-md`}
                >
                  {loading ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin-slow h-5 w-5 text-white mr-2" // Spinner animation
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Carregando...
                    </div>
                  ) : (
                    "Confirmar recuperação de senha"
                  )}
                </button>
              </span>
            </div>

            {/* Error message */}
            {error && <div className="text-red-500 text-sm">{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}
