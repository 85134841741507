/* This example requires Tailwind CSS v2.0+ */

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { URL } from '../../service/Service';


export default function WithdrawalConfirmed({ person, updateHandler }) {
    const { id } = useParams();
    const jwtToken = sessionStorage.getItem('jwt');
    const [error, setError] = useState(null);

    const [data, setData] = useState({
        name: '',
      });
    
      useEffect(() => {
        fetch(`${URL}/api/affiliates/${id}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error('Network response was not ok');
          })
          .then((fetchedData) => {
            setData( {name: fetchedData.name});
          })
          .catch((error) => {
            setError(error.message);
          });
      }, [id, jwtToken]);
    return (

        <div className="text-left mr-24 min-w-full">
            <h1 className="text-2xl font-extrabold mb-4">APROVAÇÃO DE PEDIDO DE LEVANTAMENTO</h1>
            <p className="text-md -mt-4">Como <strong>administrador</strong>, tem a certeza que pretende aprovar o pedido de levantamento efetuado pelo {data.name}?  </p>

            <button className="w-full mt-4 bg-green-cl hover:bg-green-900 text-white font-semibold focus:ring-4 py-2 px-4 rounded-lg"
                onClick={(e) => updateHandler(person, person.status, e)}>
                SIM, QUERO CONFIRMAR E APROVAR
            </button>
            <p className="mt-4 font-sm">
            <small><strong>ID DE PEDIDO</strong> <p>{person.recordID}</p></small>
            </p>
        </div>

    )
}