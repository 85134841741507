import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "../../service/Service";
import ErrorMessage from "../../components/ErrorMessage";
import SuccessMessage from "../../components/SuccessMessage";

const Notifications = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [currentTab, setCurrentTab] = useState("PENDING");

  // Fetch notifications from the API
  const fetchNotifications = async () => {
    try {
      const jwtToken = sessionStorage.getItem("jwt");
      const response = await axios.get(`${URL}/api/user/notifications`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      setNotifications(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError("Falha ao carregar as notificações.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  // Define status groups including both customerStatus and customerStatusUpdate
  const statusGroups = {
    PENDING: ["PENDING", "UPDATE_PENDING"],
    APPROVED: ["APPROVED", "UPDATE_APPROVED"],
    REJECTED: ["REJECTED", "UPDATE_REJECTED"],
  };

  // Determine the actual status to filter by
  const getStatusToFilter = (notification) => {
    if (notification.metadata.customerStatusUpdate) {
      return notification.metadata.customerStatusUpdate;
    }
    return notification.metadata.customerStatus;
  };

  // Filter notifications based on the current tab
  const filteredNotifications = notifications.filter((notification) =>
    statusGroups[currentTab].includes(getStatusToFilter(notification))
  );

  // Handle approval for standard notifications
  const handleApproval = async (
    affiliateID,
    notificationID,
    customerID,
    approvalStatus
  ) => {
    const requestBody = {
      customerStatus: approvalStatus ? "APPROVED" : "REJECTED",
    };

    try {
      const jwtToken = sessionStorage.getItem("jwt");
      await axios.post(
        `${URL}/api/affiliates/${affiliateID}/notifications/${notificationID}/customers/${customerID}/approval`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setSuccess(`Pedido ${approvalStatus ? "aprovado" : "rejeitado"} com sucesso.`);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.notificationID === notificationID
            ? {
                ...notification,
                metadata: {
                  ...notification.metadata,
                  customerStatus: approvalStatus ? "APPROVED" : "REJECTED",
                },
              }
            : notification
        )
      );
    } catch (error) {
      console.error(error);
      setError("Erro ao processar o pedido.");
    }
  };

  // Handle edition approval for updated notifications
  const handleEditionApproval = async (
    affiliateID,
    notificationID,
    customerID,
    approvalStatus
  ) => {
    const requestBody = {
      customerStatusUpdate: approvalStatus
        ? "UPDATE_APPROVED"
        : "UPDATE_REJECTED",
    };

    try {
      const jwtToken = sessionStorage.getItem("jwt");
      await axios.post(
        `${URL}/api/affiliates/${affiliateID}/notifications/${notificationID}/customers/${customerID}/editionApproval`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setSuccess(`Edição ${approvalStatus ? "aprovada" : "rejeitada"} com sucesso.`);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.notificationID === notificationID
            ? {
                ...notification,
                metadata: {
                  ...notification.metadata,
                  customerStatusUpdate: approvalStatus
                    ? "UPDATE_APPROVED"
                    : "UPDATE_REJECTED",
                },
              }
            : notification
        )
      );
    } catch (error) {
      console.error("Erro no Axios:", error);
      setError("Erro ao processar a edição.");
    }
  };

  const formatDateTime = (dateTimeString) => {
    const [date, time] = dateTimeString.split(" ");
    return `${time} ${date}`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="text-gray-500 text-lg">A carregar...</p>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-4xl mx-auto relative">
      <ErrorMessage message={error} />
      <SuccessMessage message={success} />
      <h2 className="text-2xl font-semibold mb-6">Notificações</h2>
      <div className="mb-6">
        <nav className="flex space-x-4 border-b border-gray-200 pb-2">
          {["PENDING", "APPROVED", "REJECTED"].map((status) => (
            <button
              key={status}
              className={`px-4 py-2 rounded-md transition-colors ${
                currentTab === status
                  ? `text-${status === "PENDING" ? "orange" : status === "APPROVED" ? "green" : "red"}-600 bg-${status === "PENDING" ? "orange" : status === "APPROVED" ? "green" : "red"}-100`
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setCurrentTab(status)}
            >
              {status === "PENDING" ? "Pendentes" : status === "APPROVED" ? "Aprovadas" : "Rejeitadas"}
            </button>
          ))}
        </nav>
      </div>
      <div className="space-y-4">
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div
              key={notification.notificationID}
              className="bg-white p-6 rounded-lg shadow-md flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4"
            >
              <div className="flex-1">
                <div className="mb-4">
                  <span className="block text-gray-900">
                    Pedido efetuado por{" "}
                    <strong>{notification.metadata.affiliateName}</strong>
                  </span>
                  <span className="block text-sm text-gray-600">
                    {formatDateTime(notification.createAt)}
                  </span>
                </div>
                <div>
                  {notification.metadata.customerStatus === "PENDING" && (
                    <span className="block text-gray-900">
                      A aguardar aprovação de pedido de criação de novo cliente{" "}
                      <span className="font-semibold">
                        {notification.metadata.customerName}
                      </span>
                    </span>
                  )}
                  {notification.metadata.customerStatusUpdate === "UPDATE_PENDING" && (
                    <span className="block text-gray-900">
                      A aguardar aprovação de atualização do cliente{" "}
                      <span className="font-semibold">
                        {notification.metadata.customerName}
                      </span>
                    </span>
                  )}
                  {notification.metadata.customerStatus === "APPROVED" && (
                    <span className="block text-gray-900">
                      Pedido de criação de cliente{" "}
                      <span className="font-semibold">
                        {notification.metadata.customerName}
                      </span>{" "}
                      foi aprovado.
                    </span>
                  )}
                  {notification.metadata.customerStatusUpdate === "UPDATE_APPROVED" && (
                    <span className="block text-gray-900">
                      Atualização do cliente{" "}
                      <span className="font-semibold">
                        {notification.metadata.customerName}
                      </span>{" "}
                      foi aprovada.
                    </span>
                  )}
                  {notification.metadata.customerStatus === "REJECTED" && (
                    <span className="block text-gray-900">
                      Pedido de criação de cliente{" "}
                      <span className="font-semibold">
                        {notification.metadata.customerName}
                      </span>{" "}
                      foi rejeitado.
                    </span>
                  )}
                  {notification.metadata.customerStatusUpdate === "UPDATE_REJECTED" && (
                    <span className="block text-gray-900">
                      Atualização do cliente{" "}
                      <span className="font-semibold">
                        {notification.metadata.customerName}
                      </span>{" "}
                      foi rejeitada.
                    </span>
                  )}
                </div>
              </div>
              {(notification.metadata.customerStatus === "PENDING" || notification.metadata.customerStatusUpdate === "UPDATE_PENDING") && (
                <div className="flex flex-col space-y-2">
                  {notification.metadata.customerStatus === "PENDING" && (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                        onClick={() =>
                          handleApproval(
                            notification.metadata.affiliateID,
                            notification.notificationID,
                            notification.metadata.customerID,
                            true
                          )
                        }
                      >
                        Aceitar Pedido
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                        onClick={() =>
                          handleApproval(
                            notification.metadata.affiliateID,
                            notification.notificationID,
                            notification.metadata.customerID,
                            false
                          )
                        }
                      >
                        Rejeitar Pedido
                      </button>
                    </>
                  )}
                  {notification.metadata.customerStatusUpdate === "UPDATE_PENDING" && (
                    <>
                      <button
                        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                        onClick={() =>
                          handleEditionApproval(
                            notification.metadata.affiliateID,
                            notification.notificationID,
                            notification.metadata.customerID,
                            true
                          )
                        }
                      >
                        Aceitar Edição
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                        onClick={() =>
                          handleEditionApproval(
                            notification.metadata.affiliateID,
                            notification.notificationID,
                            notification.metadata.customerID,
                            false
                          )
                        }
                      >
                        Rejeitar Edição
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-500">Nenhuma notificação disponível.</p>
        )}
      </div>
    </div>
  );
};

export default Notifications;
