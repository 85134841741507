import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFetchAdmin } from '../../components/hooks/fetchName';
import { URL } from '../../service/Service';

export function Dashboard() {
  const affiliateName = useFetchAdmin();
  const [currentHour] = useState(new Date().getHours());
  const [numberOfAffiliates, setNumberOfAffiliates] = useState(0); // State to hold the number of affiliates

  let greeting;

  const includedFeatures = [
    'Criar novos afiliados',
    'Criar taxas para afiliados',
    'Confirmar pedidos de levantamento de saldo',
    'Estado das transações ao minuto',
  ];
  const jwtToken = sessionStorage.getItem('jwt');

  useEffect(() => {
    // Fetch the number of affiliates when the component mounts
    axios
      .get(`${URL}/api/affiliates/size`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setNumberOfAffiliates(response.data); // Update the state with the number of affiliates
      })
      .catch(error => {
        console.log(error);
      });
  }, [jwtToken]);

  if (currentHour >= 0 && currentHour < 12) {
    greeting = 'Bom dia';
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = 'Boa tarde';
  } else {
    greeting = 'Boa noite';
  }

  return (
    <div className="flex flex-col sm:flex-row items-center justify-center mt-20 bg-gray-100">
      <div className="w-full items-center justify-center">
        <div className="ml-10 mx-auto">
          <h1 className="text-3xl sm:text-5xl font-bold text-gray-900 mb-4">
            {greeting}, <span className="font-light">{affiliateName.name}!</span>
          </h1>
          <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
            O seu backoffice. Controle tudo.
          </h3>
          <div>
            <h4 className="text-lg sm:text-xl font-semibold text-green-900 mb-2">O que está incluído</h4>
            <div className="border-b border-gray-200 mb-4"></div>
            <ul className="space-y-2">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex items-center">
                  <CheckCircleIcon className="h-4 sm:h-5 w-4 sm:w-5 text-green-900 mr-2" aria-hidden="true" />
                  <p className="text-sm sm:text-base text-gray-700">{feature}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 bg-white p-6 mr-10 rounded-lg shadow-md">
        <div className="text-center">
          <div className="flex justify-center">
            <UserGroupIcon className="h-36 text-green-cl mb-4" />
          </div>
          <p className="text-lg sm:text-xl font-medium text-gray-900 mb-2">Número de afiliados registados</p>
          <div className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4">{numberOfAffiliates}</div>
          <div>
            {numberOfAffiliates === 0 ? (
              <a
                href="/admin/criar_afiliado"
                className="inline-block px-6 py-3 text-base font-bold text-white bg-green-cl hover:bg-gray-900 rounded-md"
              >
                Crie o primeiro afiliado
              </a>
            ) : (
              <a
                href="/admin/afiliados"
                className="inline-block px-6 py-3 text-base font-bold text-white bg-green-cl hover:bg-gray-900 rounded-md"
              >
                Todos os afiliados
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
