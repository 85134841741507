import { PencilIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import { URL } from "../../service/Service";
import EditClientModalUser from "../../userScenes/clients/EditClientModalUser";
import ClientModal from "./ClientModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import jwtDecode from 'jwt-decode';
import EditClientModal from "./EditClientModal";

export const TableClients = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [originalPeople, setOriginalPeople] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [peopleData, setPeopleData] = useState([]);
  const { id } = useParams();
  const [openClientID, setOpenClientID] = useState(null);
  const [editClientID, setEditClientID] = useState(null); // New state for edit modal
  const jwtToken = sessionStorage.getItem("jwt");

  const role = jwtDecode(jwtToken).authorities[0].authority;
console.log(role);
  useEffect(() => {
    const getClients = async () => {
      const jwtToken = sessionStorage.getItem("jwt");
      try {
        const res = await fetch(`${URL}/api/affiliates/${id}/costumers/`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        });
        const data = await res.json();
        console.log(data);
        if (Array.isArray(data)) {
          const reversedData = [...data].reverse();
          setOriginalPeople(reversedData);
          setPeopleData(reversedData);
          setError(null);
        } else {
          setError("Unexpected response data");
        }
      } catch (error) {
        setError(error);
      }
    };
    getClients();
  }, [id]);
  console.log(originalPeople);
  const handleChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    let filteredPeople = [];
    if (searchTerm.length === 0) {
      setPeopleData(originalPeople);
      return;
    }
    filteredPeople = originalPeople.filter((person) => {
      return (
        person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.customerID.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setPeopleData(filteredPeople);
  };

  const handleDelete = async (customerID) => {
    const jwtToken = sessionStorage.getItem("jwt");
    try {
      await axios.delete(`${URL}/api/affiliates/${id}/customer/${customerID}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setPeopleData((prevPeopleData) =>
        prevPeopleData.filter((person) => person.customerID !== customerID)
      );
      setOpenClientID(null);
    } catch (error) {
      setError(error);
    }
  };

  const handleEdit = (customerID) => {
    setEditClientID(customerID); // Open edit modal with the selected customer ID
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPeople = peopleData.slice(indexOfFirstPost, indexOfLastPost);
console.log(currentPeople);
  const getStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "text-orange-600 bg-orange-100 font-bold";
      case "APPROVED":
        return "text-green-500 bg-green-100 font-bold";
      case "REJECTED":
        return "text-red-500 bg-red-100 font-bold";
      default:
        return "text-gray-500 bg-gray-100 font-bold";
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "PENDING":
        return "Pendente";
      case "APPROVED":
        return "Aprovado";
      case "REJECTED":
        return "Rejeitado";
      default:
        return "Desconhecido";
    }
  };

  return (
    <div className="overflow-hidden px-6 bg-white shadow sm:rounded-md p-4 mb-10">

    <div className="space-y-6 sm:px-auto mt-10 lg:col-span-9 lg:px-0">
      <div className="py-6 -mt-8 md:items-center md:justify-between">
        <div className="flex">
          <input
            type="text"
            placeholder="Pesquisar por Nome ou CID"
            className="outline-none bg-white border-gray-300 focus:ring-green-900 rounded-lg py-2 px-4 block w-5/6 appearance-none leading-normal"
            onChange={handleChange}
            value={searchTerm}
          />
          <ClientModal />
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>               
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        CID
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Nome
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Data e hora do último levantamento
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Data de Registo
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Estado
                        </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        {/* Delete */}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-white bg-white">
                    {currentPeople.map((client) => (
                      <tr key={client.customerID}>
                     
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="">
                              <div className="text-black">
                                {client.customerID}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                          <div className="text-gray-900">{client.name}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-black">
                          {client.dateCalculate}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-black font-bold">
                          {client.createAt}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                            <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(client.customerStatus)}`}>
                              {getStatusLabel(client.customerStatus)}
                            </span>
                          </td>
                          <td className="whitespace-nowrap text-sm sm:pl-6">
                            <div className="flex items-center">
                              <button
                                onClick={() => handleEdit(client.customerID)}
                                className="text-green-cl hover:text-green-cl mr-2"
                              >
                                <PencilIcon className="h-5 w-5" />
                              </button>
                              <ConfirmDeleteModal customerID={client.customerID} handleDelete={handleDelete} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={peopleData.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {editClientID && (
        <EditClientModal
          customerID={editClientID}
          onClose={() => setEditClientID(null)}
        />
      )}
    </div>
  );
};