import {faDownload, faLandmarkFlag} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CheckCircleIcon, CreditCardIcon, ExclamationCircleIcon,} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import ModalAll from "../../scenes/ModalAll";
import {URL} from "../../service/Service";
import WithdrawalConfirmed from "./WithdrawalConfirmed";
import WithdrawalFormAdmin from "./WithdrawalFormAdmin";

export const RecordsUserAdminPage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [originalPeople, setOriginalPeople] = useState([]);
	const [peoplePerPage] = useState(5);
	const jwtToken = sessionStorage.getItem("jwt");
	const [error, setError] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");
	const [peopleData, setPeopleData] = useState(originalPeople);
	const [data, setData] = useState({status: "COMPLETED"});
	const {id} = useParams();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${URL}/api/affiliates/${id}/records`, {
					headers: {
						Authorization: `Bearer ${jwtToken}`,
						"Content-Type": "application/json",
					},
				});
				if (response.ok) {
					const data = await response.json();
					const filteredData = data.filter(
						(person) => person.recordAmount !== 0
					);
					setOriginalPeople(filteredData.reverse());
					setPeopleData(filteredData);
				} else {
					throw new Error("Failed to fetch data");
				}
			} catch (error) {
				setError(error);
			}
		};
		if (id) {
			fetchData();
		}
	}, [id, jwtToken, setError]);

	const hasPendingWithdrawal = () => {
		return peopleData.some((person) =>
			person.recordStatus[person.recordStatus.length - 1].includes("PENDING")
		);
	};

	const handleOpenWithdrawalModal = () => {
		if (hasPendingWithdrawal()) {
			setErrorMessage(
				"É necessário finalizar os pedidos de levantamento pendentes antes de realizar um novo pedido."
			);
			return false; // Return false if there's an error
		} else {
			setErrorMessage("");
			return true; // Return true to allow modal to open
		}
	};

	const handleDownloadClick = (person) => {
		console.log(person.affiliateID);
		const endpoint = `http://localhost:8080/api/export-csv/withdrawal/affiliates/${person.affiliateID}`;

		fetch(endpoint, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${jwtToken}`,
			},
		})
			.then((response) => {
				if (!response.ok) {
					return response.text().then((text) => {
						console.error("Error Response:", text);
						throw new Error("Failed to download CSV");
					});
				}

				return response.blob().then((blob) => ({blob}));
			})
			.then(({blob}) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `withdrawal - ${person.affiliateID}.csv`);

				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url); // Clean up after download
			})
			.catch((error) => {
				console.error("Error downloading CSV:", error);
			});
	};


	const getStatusClass = (recordStatus) => {
		const lastRecordStatus = recordStatus[recordStatus.length - 1];
		let statusClass;
		switch (lastRecordStatus) {
			case "COMPLETED":
				statusClass = "bg-green-100 text-green-800";
				break;
			case "PENDING":
				statusClass = "bg-yellow-100 text-yellow-800";
				break;
			case "CANCELED":
				statusClass = "bg-red-100 text-red-800";
				break;
			case "REFUNDED":
				statusClass = "bg-orange-100 text-orange-800";
				break;
			default:
				statusClass = "bg-gray-100 text-gray-800";
		}
		return statusClass;
	};

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		setSearchTerm(searchTerm);

		let filteredData = originalPeople;
		if (searchTerm) {
			filteredData = originalPeople.filter((data) => {
				const statusString =
					data.recordStatus[data.recordStatus.length - 1].split(" ")[0];
				return (
					data.recordId.toLowerCase().includes(searchTerm) ||
					statusString.toLowerCase().includes(searchTerm)
				);
			});
		}

		setPeopleData(filteredData);
		setCurrentPage(1);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	// Pagination logic
	const indexOfLastPerson = currentPage * peoplePerPage;
	const indexOfFirstPerson = indexOfLastPerson - peoplePerPage;
	const currentPeople = peopleData.slice(indexOfFirstPerson, indexOfLastPerson);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const updateHandler = (person, recordId, e) => {
		console.log(person.recordID);
		console.log("Person Data:", person); // Adicione este log
		console.log("Record ID:", person.recordID); // E este log para verificar se o ID está correto

		fetch(`${URL}/api/affiliates/${id}/records/${person.recordID}/complete`, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify(data),
		})
			.then((response) => {
				if (response.ok) {
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				}
			})
			.catch((error) => {
				// handle error
			});
	};

	return (
		<div className="bg-white p-4 md:p-8">
			<div className=" ">
				<div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<ExclamationCircleIcon
								className="h-5 w-5 text-yellow-400"
								aria-hidden="true"
							/>
						</div>
						<div className="ml-3">
							<p className="text-sm text-yellow-700">
								Caso tenha algum{" "}
								<strong>
									<span className="italic">status pending</span>, clique no{" "}
									<span className="inline-flex items-center">
					<span>botão</span>{" "}
										<CheckCircleIcon
											className="w-5 h-5 flex items-center justify-between ml-1 text-green-cl"/>
				  </span>
								</strong>{" "}
								que aparecerá na tabela para confirmar o pedido.
							</p>
						</div>
					</div>
				</div>
				<div className="sm:flex sm:items-center mt-6">
					<div className="sm:flex-auto">
						<h1 className="text-2xl font-extrabold text-gray-900">
							GESTÃO DE TRANSAÇÕES MONETÁRIAS
						</h1>
						<p className="-mt-1 text-sm text-gray-700">
							Todas as transações sobre pedidos de transações encontram-se aqui.
						</p>
					</div>
					<div className="relative mt-4 sm:mt-0 sm:ml-4 sm:flex-none">
						<ModalAll
							isOpen={isModalOpen}
							onClose={() => setIsModalOpen(false)}
							nameButton="Levantar Dinheiro"
							onClick={handleOpenWithdrawalModal}
						>
							<WithdrawalFormAdmin/>
						</ModalAll>
						{errorMessage && (
							<p
								className="absolute top-full mt-2 w-[400px] bg-red-100 border border-red-200 text-red-600 text-sm p-2 rounded-md shadow-md z-10"
								style={{
									left: window.innerWidth < 768 ? "0" : "-200px",
									right: "auto",
									maxWidth: "90vw",
								}}
							>
								{errorMessage}
							</p>
						)}
					</div>
				</div>
				<div className="mt-8 relative">
					<input
						type="text"
						placeholder="Pesquise por ID ou Status"
						className="pl-10 pr-10 outline-none bg-white border border-gray-300 rounded-lg py-2 px-4 block w-full leading-normal focus:border-green-900 focus:ring-transparent"
						onChange={handleSearch}
						value={searchTerm}
					/>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="currentColor"
						className="w-6 h-6 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
						/>
					</svg>
				</div>

				<div name="TransactionTableAdminDiv" className="mt-8">
					<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
							<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
								<table
									name="TransactionTableAdmin"
									className="min-w-full divide-y divide-gray-300"
								>
									<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
										>
											ID de Pedido
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
										>
											Tipo de Pedido
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
										>
											Valor da taxa
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
										>
											Status
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
										>
											Saldo Atual
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
										>
											<span className="sr-only">Download CSV</span>
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											<span className="sr-only">Edit</span>
										</th>
									</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200">
									{currentPeople.map((person) => (
										<tr key={person.recordID}>
											<td className="whitespace-nowrap px-3 py-4 sm:pl-6">
												<div className="flex items-center">
													<div className="h-10 w-10 flex-shrink-0">
														{person.recordType === "WITHDRAWAL" ? (
															<FontAwesomeIcon
																className="h-10 w-6 flex-shrink-0"
																icon={faLandmarkFlag}
															/>
														) : (
															<CreditCardIcon
																className="h-10 w-6 flex-shrink-0"
																icon={faLandmarkFlag}
															/>
														)}
													</div>
													<div className="inline-flex flex-col">
														<div className="text-gray-900 px-2">
															{person.recordID}
														</div>
														<div className="text-gray-500 px-2">
															{person.recordDate}
														</div>
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-center text-black">
						  <span
							  className="inline-flex rounded-full bg-gray-300 bg-opacity-60 font-semibold leading-5 px-3 text-black mx-auto">
							{person.recordType === "WITHDRAWAL"
								? "Levantamento"
								: "Depósito"}
						  </span>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-center text-black">
												{person.recordStatus[person.recordStatus.length - 1]
													.split(" ")[0]
													.includes("REFUNDED")
													? "-"
													: "+"}{" "}
												{(Math.ceil(person.recordAmount * 100) / 100)
													.toFixed(2)
													.replace(".", ",")}{" "}
												€
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">
						  <span
							  className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getStatusClass(
								  person.recordStatus
							  )}`}
						  >
							{
								person.recordStatus[
								person.recordStatus.length - 1
									].split(" ")[0]
							}
						  </span>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm font-bold text-center text-black">
												{(Math.ceil(person.balance * 100) / 100)
													.toFixed(2)
													.replace(".", ",")}{" "}
												€
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-center text-sm">
												{person.recordType === "WITHDRAWAL" &&
													person.recordStatus[person.recordStatus.length - 1]
														.trim()
														.toUpperCase()
														.startsWith("PENDING") && (
														<div className="flex justify-center">
															<FontAwesomeIcon
																icon={faDownload}
																className="text-blue-500 cursor-pointer hover:text-blue-700"
																onClick={() => handleDownloadClick(person)}
															/>
														</div>
													)}
											</td>
											<td className="whitespace-nowrap inline-flex items-center py-4 text-right text-sm font-medium sm:pr-6">
												{/*<ModalAll icon={<DocumentDuplicateIcon className="w-5 h-5" />}>
													<RequestsHistoric
													  recordStatus={currentPeople[index].recordStatus}
													  recordId={currentPeople[index].recordId}
													  recordAmount={currentPeople[index].recordAmount}
													/>
												  </ModalAll> */}
												<div
													className="flex border-md rounded-full items-center justify-between">
													<div className="flex-1 text-center">
														{person.recordStatus[
														person.recordStatus.length - 1
															].startsWith("PENDING") && (
															<ModalAll
																icon={
																	<CheckCircleIcon className="w-5 h-5 text-green-cl"/>
																}
															>
																<WithdrawalConfirmed
																	person={person}
																	updateHandler={updateHandler}
																/>
															</ModalAll>
														)}
													</div>
												</div>
											</td>
										</tr>
									))}
									</tbody>
								</table>
								{originalPeople.length > peoplePerPage && (
									<Pagination
										postsPerPage={peoplePerPage}
										totalPosts={originalPeople.length}
										paginate={paginate}
										currentPage={currentPage}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
