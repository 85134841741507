import React, { useState } from 'react';
import ErrorMessage from '../ErrorMessage';
import SuccessMessage from '../SuccessMessage';
import LoadingSpinner from './LoadingSpinner';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { URL } from '../../service/Service';
import { EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/react/24/outline';

const newPasswordEndpoint = '/api/admin/newPassword';

const Password = () => {
  const jwtToken = sessionStorage.getItem('jwt');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [messageErr, setMessageErr] = useState('');
  const [subtitleErr, setSubtitleErr] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setMessageErr('');
    setSubtitleErr('');

    try {
      setIsLoading(true);
      const response = await fetch(`${URL}${newPasswordEndpoint}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(formValues),
      });
      const responseData = await response.json();

      if (responseData.success) {
        setSuccessMessage(responseData.message);
        setFormValues({
          oldPassword: '',
          newPassword: '',
          repeatNewPassword: '',
        });
      } else {
        setErrorMessage(responseData.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      setMessageErr('Não foi possível criar o cliente.');
      setSubtitleErr(
        'Preencha todos os campos ou verifique se o ID de Cliente já existe e volte a tentar.'
      );
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        clearErrorMessages();
      }, 3000);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const clearErrorMessages = () => {
    setMessageErr('');
    setSubtitleErr('');
  };

  return (
    <div className="bg-white rounded-md">
      <div className="flex items-center justify-center">
        <div className="w-full">
          <div>
            <h2 className="text-left text-xl font-extrabold text-gray-900">Alterar Palavra-Passe</h2>
          </div>
          <form id="password-form" onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div>
              {successMessage && <SuccessMessage message={successMessage} />}
              {messageErr && (
                <ErrorMessage messageErr={messageErr} subtitleErr={subtitleErr} duration={2000} />
              )}
              <div className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <label
                  htmlFor="current-password"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                  Palavra-Passe atual
                </label>
                <input
                  type={showCurrentPassword ? 'text' : 'password'}
                  name="oldPassword"
                  id="current-password"
                  autoComplete="current-password"
                  className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  onChange={handleChange}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                      aria-label="Hide current password"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                      aria-label="Show current password"
                    />
                  )}
                </div>
              </div>
              <div className="relative mt-6 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <label
                  htmlFor="new-password"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                  Nova Palavra-Passe
                </label>
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  name="newPassword"
                  id="new-password"
                  autoComplete="new-password"
                  className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  onChange={handleChange}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                      aria-label="Hide new password"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                      aria-label="Show new password"
                    />
                  )}
                </div>
              </div>
              <div className="relative mt-6 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <label
                  htmlFor="confirm-password"
                  className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                  Repita a nova Palavra-Passe
                </label>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="repeatNewPassword"
                  id="confirm-password"
                  autoComplete="confirm-password"
                  className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  onChange={handleChange}
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                      aria-label="Hide confirm password"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                      aria-label="Show confirm password"
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <PasswordStrengthIndicator password={formValues.repeatNewPassword} />
            </div>
            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-cl hover:bg-green-eupago focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-cl"
                required
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon
                        className="h-5 w-5 text-green-eupago group-hover:text-green-cl"
                        aria-hidden="true"
                      />
                    </span>
                    Confirmar
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Password;
