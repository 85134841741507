import React, {useState} from "react";

const ModalAll = ({children, isOpen, onClose, nameButton, icon, onClick}) => {
  const [isShowing, setIsShowing] = useState(isOpen);

  const closeModal = () => {
    setIsShowing(false);
    onClose && onClose();
  };

  const openModal = () => {
    const shouldOpen = onClick ? onClick() : true;
    if (shouldOpen) {
      setIsShowing(true);
    }
  };

  return (
    <>
     <button
  onClick={openModal}
  className={`${
    icon ? 'inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-green-cl hover:border-green-900 focus:outline-none sm:w-auto ' : 'inline-flex items-center justify-center rounded-md border border-transparent bg-green-cl px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-900  sm:w-auto '
  }`}
>
  {icon && <span className="">{icon}</span>}
  {nameButton}
</button>
      {isShowing && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-50 z-40"
            onClick={closeModal}
          ></div>
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
            <div className="bg-white rounded-lg p-6 shadow-lg">
              
              <div className="flex">
              <button
                  className="text-gray-700 -mt-4 hover:text-gray-600"
                  onClick={closeModal}
                >
                  <svg viewBox="0 0 20 20" className="absolute top-4 right-6 bottom-0 w-6 h-6">
                    <path
                      fill="currentColor"
                      d="M14.348 5.652a.875.875 0 011.237 1.237L11.237 10l4.348 4.348a.875.875 0 11-1.237 1.237L10 11.237l-4.348 4.348a.875.875 0 11-1.237-1.237L8.763 10 4.415 5.652A.875.875 0 115.652 4.415L10 8.763l4.348-4.348a.875.875 0 011.237 0z"
                    />
                  </svg>
                </button>
              <div className=" ml-auto mt-auto">{children}</div>
               
              </div>
              
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ModalAll;
