import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import AlertError from '../../scenes/partner/AlertError';
import AlertSuccess from '../../scenes/partner/AlertSuccess';
import { URL } from '../../service/Service';
import { useFetchAdmin } from '../hooks/fetchName';

const API_URL = `${URL}/api`;

const AdminInfo = () => {
  const adminName = useFetchAdmin();
  const jwtToken = sessionStorage.getItem('jwt');

  const [error, setError] = useState(null);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [unSuccessUpdate, setUnSuccessUpdate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [data, setData] = useState({ email: '', name: '' });
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

  const UPDATE_SUCCESS_MESSAGE = `Nome alterado para ${data.name}`;
  const UPDATE_ERROR_MESSAGE =
    'Ocorreu um problema ao atualizar as informações do parceiro. Por favor, verifique os dados inseridos e tente novamente.';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/getAdmin`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (response.ok) {
          const fetchedData = await response.json();
          setData(fetchedData);
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [jwtToken]);

  useEffect(() => {
    let timeoutId;
    if (successUpdate) {
      timeoutId = setTimeout(() => {
        setSuccessUpdate(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [successUpdate]);

  useEffect(() => {
    if (isEditing) {
      setNameInput(data.name);
    }
  }, [isEditing, data.name]);

  const updateHandler = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return; // Prevent multiple submissions
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_URL}/admin/update`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ ...data, name: nameInput }),
      });

      if (response.ok) {
        setSuccessUpdate(true);
        setData((prevState) => ({ ...prevState, name: nameInput }));
        setIsEditing(false);
      } else if (response.status === 400) {
        setUnSuccessUpdate(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    setNameInput(event.target.value);
  };

  const toggleEditMode = () => {
    setIsEditing((prevState) => !prevState);
    setUnSuccessUpdate(false); // Reset the unSuccessUpdate state
    setNameInput(''); // Clear the nameInput state when exiting editing mode
  };

  return (
    <>
      {error && <AlertError message={error} />}
      {unSuccessUpdate && <AlertError message={UPDATE_ERROR_MESSAGE} />}
      {successUpdate && !isEditing && (
        <AlertSuccess message={UPDATE_SUCCESS_MESSAGE} />
      )}
      <div className="">
      <div>
            <h2 className="text-2xl  mt-4 font-extrabold text-gray-900 mb-6">
              Informações de conta
            </h2>
          </div>
        <form onSubmit={updateHandler} className="space-y-4">
          <div className="flex items-center space-x-4">
            <label
              htmlFor="name"
              className="w-1/4 text-sm font-medium text-gray-500"
            >
              Nome
            </label>
            <span className="flex-grow">{data.name}</span>

          </div>

          <div className="flex items-center space-x-4">
            <label
              htmlFor="email"
              className="w-1/4 text-sm font-medium  text-gray-500"
            >
              Email
            </label>
            <span className="flex-grow">{adminName?.email}</span>
          </div>

          <div className="flex items-center space-x-4">
            <label
              htmlFor="role"
              className="w-1/4 text-sm font-medium text-gray-500"
            >
              Função
            </label>
            <span className="flex-grow font-bold">{adminName?.role === "ADMIN" ? "Admin" : "Gestor"}</span>
          </div>

          <div className="flex items-center space-x-4">
            <label
              htmlFor="timestamp"
              className="w-1/4 text-sm font-medium text-gray-500"
            >
              Data de criação
            </label>
            <span className="flex-grow">{adminName?.createAt}</span>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminInfo;
