import React, { useState, useEffect } from 'react';
import AccountBalance from './AccountBalance';
import DashboardStatsUser from './DashboardStatsUser';
import useFetchAffiliate from './useFetchAffiliate';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const DashboardUser = () => {
  const [currentHour, setCurrentHour] = useState(new Date().getHours());
  const jwtToken = sessionStorage.getItem('jwt');
  const data = useFetchAffiliate(jwtToken);
  const includedFeatures = [
    'Lista de clientes',
    'Verificação de saldo atual e acumulado',
    'Pedidos de levantamento de saldo',
    'Estado das transações ao minuto',
  ];

  useEffect(() => {
    setCurrentHour(new Date().getHours());
  }, []);

  const getGreeting = () => {
    if (currentHour >= 0 && currentHour < 12) {
      return 'Bom dia';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Boa tarde';
    } else {
      return 'Boa noite';
    }
  };

  return (
    <div className="ml-10 mr-10">
      <AccountBalance />
      <div className="bg-gray-100">
        <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="mx-auto w-full overflow-hidden rounded-lg lg:flex lg:max-w-none">
              <div className="flex-1 py-8 lg:p-12">
                <div className="mt-8 flex flex-col items-start sm:flex-row sm:items-center sm:space-x-4">
                  <h1 className="text-7xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight">
                    {`${getGreeting()}, `}
                    <span className="font-thin">{data.name}!</span>
                  </h1>
               {/*    <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://eupago.atlassian.net/servicedesk/customer/portal/2"
                    className="flex items-center justify-center rounded-md border border-transparent bg-green-cl px-5 py-3 text-base font-bold text-white hover:bg-gray-900"
                  >
                    Dúvidas? <span className="font-thin ml-2">Contacte a nossa equipa de suporte</span>
                  </a> */}
                </div>
                <h3 className="text-xl mt-6 font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight">
                  A sua plataforma. A sua gestão.
                </h3>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 text-base font-semibold text-green-900">
                      O que está incluído
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-900" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardUser;
