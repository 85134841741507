import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import CreateRate from './CreateRate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
export const RateModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const cancelButtonRef = useRef(null);


    const handleClickOpen = () => {
        setIsOpen(true);
    };
    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const buttonStyles = {
        cancel: "mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-cl focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",
        submit: "inline-flex w-full justify-center rounded-md border border-transparent bg-green-cl px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-cl focus:outline-none focus:ring-2 hover:opacity-90 focus:ring-offset-2 sm:w-auto sm:text-sm"
    };
    const button2 = (

        <button
            type="button"
            className={buttonStyles.cancel}
            onClick={handleClose}
            ref={cancelButtonRef}
        >
            Cancelar
        </button>
    );

    const button1 = (
        <button
            type="submit"
            className={buttonStyles.submit}
            onClick={() => {

                setIsOpen(false);
            }}>
            Criar/Atualizar
        </button>
    );
    return (
        <>
            <button onClick={handleClickOpen}
                className='p-10 bg-gray-50 rounded-lg shadow-sm overflow-hidden transition-colors border-2 border-opacity-10 border-green-cl duration-300 hover:bg-gray-100 cursor-pointer'
            >
                <div className="text-gray-500 text-center">

                    <p className="text-xl font-semibold mb-2">Sem taxas disponíveis</p>
                    <p className="text-sm">
                        Crie algumas taxas no botão acima para começar.
                    </p>
                    <FontAwesomeIcon icon={faSquarePlus} size="2xl" className='mt-4 h-20' />
                </div>
            </button>
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >

                                <Dialog.Panel className="relative rounded-lg bg-white px-4 text-left shadow-xl sm:my-8 sm:w-auto sm:p-6 ">
                                    <div className="bg-white rounded-lg p-4 sm:flex sm:items-start">
                                        <CreateRate button1={button1} button2={button2} />
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default RateModal