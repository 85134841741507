import React from "react";
import { useState } from "react";

export default function Pagination({ postsPerPage, totalPosts, paginate, currentPage }) {
    const [showPagination, setShowPagination] = useState(true);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (isNaN(postsPerPage) || isNaN(totalPosts) || isNaN(currentPage)) {
        setShowPagination(false);
    }

    return (
        <>
            {showPagination && (
                <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                >
                    <div className="hidden sm:block">
                        {totalPosts > 0 ? (
                            <p className="text-sm text-gray-700">
                                Exibindo <span className="font-medium">{postsPerPage * (currentPage - 1) + 1}</span> a{' '}
                                <span className="font-medium">{Math.min(postsPerPage * currentPage, totalPosts)}</span> de{' '}
                                <span className="font-medium">{totalPosts}</span> resultados
                            </p>
                        ) : (
                            <p className="text-sm text-gray-700">Nenhum resultado encontrado.</p>
                        )}
                    </div>
                    {totalPosts > 0 && (
                        <div className="flex flex-1 justify-between sm:justify-end">
                            {currentPage !== 1 && (
                                <button
                                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={() => paginate(currentPage - 1)}
                                >
                                    Anterior
                                </button>
                            )}
                            {currentPage !== pageNumbers.length && (
                                <button
                                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={() => paginate(currentPage + 1)}
                                >
                                    Próximo
                                </button>
                            )}
                        </div>
                    )}
                </nav>
            )}
        </>
    );
}
