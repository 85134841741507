import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import Password from './Password';
import AdminInfo from './AdminInfo';
import Breadcrumb from '../breadcrumb/breadcrumb';
import { Settings } from './Settings';

const AccountSettings = () => {
  const [role, setRole] = useState('');
  const pages = [{ name: role === 'admin' ? 'Definições de Administrador' : 'Definições de Gestor', href: '/admin/geral', current: true }]

  useEffect(() => {
    const token = sessionStorage.getItem('jwt');
    if (token) {
      const decoded = jwtDecode(token);
      const userRole = decoded.authorities[0].authority.toLowerCase();
      setRole(userRole);
    }
  }, []);

  return (
    <>
      <div className="ml-10 mr-10">
        <div className="flex justify-between">
          <Breadcrumb pages={pages} />
        </div>

        <h1 className="text-3xl mt-14 uppercase font-extrabold leading-6 text-black">Definições de conta</h1>
        <p className="text-sm mt-4 text-gray-500">
          Aqui encontra a sua <strong className="text-black">área de gestão de conta</strong>, onde pode facilmente visualizar e atualizar as suas informações
          pessoais e de contacto, para garantir uma experiência de utilizador personalizada e segura.
        </p>
       
          {/* <AdminInfo />
          <Password /> */}
          <Settings/>
         </div>
    </>
  );
};

export default AccountSettings;
