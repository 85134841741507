import { ArrowPathRoundedSquareIcon, ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import { URL } from '../../service/Service';
import ErrorMessage from '../../components/ErrorMessage';
import SuccessMessage from '../../components/SuccessMessage';

export default function Create() {
    const pages = [
        { name: 'Lista de Afiliados', href: '/admin/afiliados', current: false },
        { name: 'Criar Afiliado', href: '/admin/criar_afiliado', current: true },
    ];
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        nif: '',
        name: '',
        adress: '',
        zipCode: '',
        notes: '',
        url: '',
        iban: '',
        softwareType: 'NA',  // ALWAYS SET A DEFAULT VALUE (NA) TO AVOID EMPTY VALUES - REQUIRED TO CREATE A NEW AFFILIATE (ignore - not used, but its not to delete)
        bicSwift: '',
    });

    const [messageErr, setMessageErr] = useState("");
    const [subtitleErr, setSubtitleErr] = useState("");
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    // add state variables to track errors for each field
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nifError, setNifError] = useState('');
    const [nameError, setNameError] = useState('');
    const [bicSwiftError, setBicSwiftError] = useState('');
    const [adressError, setAddressError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');
    const [notesError, setNotesError] = useState('');
    const [urlError, setUrlError] = useState('');
    const [rateTypeError, setRateTypeError] = useState('');
    const [ibanError, setIbanError] = useState('');
    const [softwareTypeError, setSoftwareTypeError] = useState(''); // Software Type - ignore - not used, but its not to delete - required to create a new affiliate (default value is NA)


    const token = sessionStorage.getItem('jwt');
    const clearErrorMessages = () => {
        setMessageErr("");
        setSubtitleErr("");
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        switch (name) {
            case 'email':
                setEmailError('');
                break;
            case 'password':
                setPasswordError('');
                break;
            case 'nif':
                setNifError('');
                break;
            case 'name':
                setNameError('');
                break;
            case 'adress':
                setAddressError('');
                break;
            case 'zipCode':
                setZipCodeError('');
                break;
            case 'notes':
                setNotesError('');
                break;
        
            case 'bicSwift':
                setBicSwiftError('');
                break;
            case 'rateType':
                setRateTypeError('');
                break;
            case 'iban':
                setIbanError('');
                break;
            // Software Type - ignore - not used, but its not to delete - required to create a new affiliate (default value is NA)
            case 'softwareType':
                setSoftwareTypeError('');
                break;
            default:
                break;
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        // clear all errors before validating
        setEmailError('');
        setPasswordError('');
        setNifError('');
        setNameError('');
        setAddressError('');
        setZipCodeError('');
        setNotesError('');
    setRateTypeError('');
        setIbanError('');
        setBicSwiftError('');
        setSoftwareTypeError(''); // Software Type - ignore - not used, but its not to delete - required to create a new affiliate (default value is NA)
        setMessageErr("");
        setSubtitleErr("");
        // validar cada campo
        if (!formData.email) {
            setEmailError('Email obrigatório/incorreto');
        }
        if (!formData.password) {
            setPasswordError('Password obrigatória/incorreta');
        }
        if (!formData.nif) {
            setNifError('NIF é obrigatório');
        }
        if (!formData.name) {
            setNameError('Nome é obrigatório');
        }
        if (!formData.adress) {
            setAddressError('Morada é obrigatória');
        }
        if (!formData.zipCode) {
            setZipCodeError('Código postal é obrigatório');
        }
     
        if (!formData.iban) {
            setIbanError('IBAN é obrigatório');
        }
        // Software Type - ignore - not used, but its not to delete - required to create a new affiliate (default value is NA)
        if (!formData.softwareType) {
            setSoftwareTypeError('Tipo de software é obrigatório');
        }
        if (!formData.bicSwift) {
            setBicSwiftError('SWIFT é obrigatório');
        }


        // check if there are any errors
        if (
            emailError ||
            passwordError ||
            nifError ||
            nameError ||
            adressError ||
            zipCodeError ||
            ibanError ||
            // Software Type - ignore - not used, but its not to delete - required to create a new affiliate (default value is NA)
            softwareTypeError ||
            bicSwiftError
        ) {
            return;
        }

        try {
            const res = await axios.post(`${URL}/api/affiliates/create`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (res.data.success) {
                setSuccessMessage(res.data.message);
                setMessageErr("");
                setTimeout(() => {
                    navigate('/admin/afiliados');
                }, 2000);
            } else {
                setErrorMessage(res.data.message);
                setMessageErr(res.data.message);
                setSuccessMessage("Novo afiliado criado com sucesso.");
                setTimeout(() => {
                    navigate('/admin/afiliados'); // Navigate to "/listaafiliados" after 2 seconds
                    // Reload the page after 3 seconds
                }, 2000);
            }
        } catch (error) {
            console.error("Error response:", error.response);
            if (error.response) {
                // If the data is a string, use it directly
                const backendMessage = typeof error.response.data === 'string' ? error.response.data : "Erro ao criar o afiliado.";
                setMessageErr(backendMessage);
                setSubtitleErr("Verifique os dados e tente novamente.");
            } else {
                setMessageErr("Não foi possível criar o afiliado.");
                setSubtitleErr("Preencha todos os campos ou verifique se o Afiliado já existe e volte a tentar.");
            }
            setSuccessMessage("");
            setTimeout(() => {
                clearErrorMessages();
            }, 3000);
        }
    };

    const generatePassword = () => {
        let password = '';
        const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 8; i++) {
            password += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
        }
        return password;
    };

    const handleGeneratePassword = () => {
        const generatedPassword = generatePassword();
        setFormData({
            ...formData,
            password: generatedPassword,
        });
    };

    return (

        <div className="ml-10 mr-10">
            <div className="px-4 sm:px-6 md:px-0" >
                <div class="flex justify-between">
                    <Breadcrumb pages={pages} />

                </div>
                <Tooltip />

                <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
                    <div className="fixed inset-x-0 top-0 z-50 w-full">
                        {successMessage && <SuccessMessage message={successMessage} />}
                        {messageErr && (
                            <ErrorMessage
                                messageErr={messageErr}
                                subtitleErr={subtitleErr}
                                duration={2000}
                                className="w-full"
                            />
                        )}
                    </div>

                    <div className="space-y-8 divide-y  divide-gray-200">

                        <div className="pt-8">

                            <div>
                                <h3 className="text-3xl font-black uppercase text-green-cl" >Novo Afiliado</h3>
                                <p className="mt-1 text-sm text-gray-500">Crie um novo afiliado para a sua empresa.</p>
                            </div>
                            <div className="bg-white rounded-md mt-4 px-10 p-4">

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-3 mt-1">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Nome
                                        </label>
                                        <div className="relative rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                autoComplete="given-name"
                                                className={`block w-full rounded-md ${nameError ? 'border-red-300' : 'border-gray-300'} pr-10 text-black placeholder-red-300 focus:border-green-900 focus:outline-none border-gray-300 focus:ring-green-cl sm:text-sm`}
                                                value={formData.name}
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (!formData.name) {
                                                        setNameError('Por favor, coloque um nome.'); // Display error if input is empty and form is submitted
                                                    } else {
                                                        setNameError('');
                                                    }
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="name-error"
                                            />

                                            {nameError && (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {nameError && (
                                            <p className="ml-1 text-sm text-red-500">{nameError}</p>
                                        )}
                                    </div>



                                    <div className="sm:col-span-3">
                                        <label htmlFor="nif" className="block text-sm font-medium text-gray-700">
                                            Número de Identificação Fiscal
                                        </label>
                                        <div className="mt-1 relative">
                                            <input
                                                type="text"
                                                name="nif"
                                                id="nif"
                                                autoComplete="family-name"
                                                className={`block w-full rounded-md ${nifError ? 'border-red-300' : 'border-gray-300'} pr-10 text-black placeholder-red-300 focus:border-green-900 focus:outline-none border-gray-300 focus:ring-green-cl sm:text-sm`}
                                                value={formData.nif}
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (!formData.nif) {
                                                        setNifError('Por favor, coloque um NIF.'); // Display error if input is empty and form is submitted
                                                    } else {
                                                        setNifError('');
                                                    }
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="nif-error"
                                            />
                                            {nifError && (
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {nifError && (
                                            <p className="ml-1 text-sm text-red-500">{nifError}</p>
                                        )}
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email
                                        </label>
                                        <div className="relative rounded-md shadow-sm">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                className={`block w-full pl-10 rounded-md ${emailError ? 'border-red-300' : 'border-gray-300'} pr-10 mr-2  text-black placeholder-red-300 focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                value={formData.email}
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (!formData.email) {
                                                        setEmailError('Por favor, coloque um email.'); // Display error if input is empty and form is submitted
                                                    } else {
                                                        setEmailError('');
                                                    }
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="email-error"
                                            />
                                            {emailError && (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {emailError && (
                                            <p className="ml-1 text-sm text-red-500">{emailError}</p>
                                        )}
                                        {!emailError && (
                                            <p className="mt-2 text-sm text-gray-500">example@domain.com</p>
                                        )}
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <div className="relative rounded-md shadow-sm">
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <button
                                                    type="button"
                                                    onClick={handleGeneratePassword}
                                                    className="top-2 mr-2 right-24 h-4 w-5 flex items-center justify-center text-green-cl rounded-full animate-spin"
                                                    style={{
                                                        animationDuration: "5s",
                                                        animationDelay: "0.5s",
                                                        animationIterationCount: "infinite",
                                                    }}
                                                    data-tip="Gerar informações"
                                                >
                                                    <ArrowPathRoundedSquareIcon />
                                                </button>
                                            </div>
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                id="password"
                                                autoComplete="family-name"
                                                className={`block w-full rounded-md ${passwordError ? 'border-red-300' : 'border-gray-300'} pr-10 pl-10 text-black placeholder-red-300 focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                value={formData.password}
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (!formData.password) {
                                                        setPasswordError('Por favor, defina ou clique no botão para gerar uma password.'); // Display error if input is empty and form is submitted
                                                    } else {
                                                        setPasswordError(false);
                                                    }
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="password-error"
                                            />
                                            {passwordError && (
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                            {passwordError ? null : (
                                                <button
                                                    type="button"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    className="absolute top-2 right-4 h-6 w-4 flex items-center justify-center text-green-cl rounded-full"
                                                >
                                                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                                                </button>
                                            )}
                                        </div>
                                        <p className="ml-1 text-sm text-red-500">{passwordError}</p>
                                    </div>



                                    <div className="sm:col-span-3" style={{ display: 'none' }}>
                                        <label htmlFor="softwareType" className="block text-sm font-medium text-gray-700">
                                            Tipo de Software
                                        </label>
                                        <div className="mt-1 relative">
                                            <select
                                                id="softwareType"
                                                name="softwareType"
                                                autoComplete="softwareType"
                                                className={`block w-full rounded-md ${softwareTypeError ? 'border-red-300' : 'border-gray-300'} pr-10 text-black placeholder-red-300 focus:border-green-900 focus:outline-none border-gray-300 focus:ring-green-cl sm:text-sm`}
                                                value={formData.softwareType} //ALWAYS SET A DEFAULT VALUE (NA) TO AVOID EMPTY VALUES - REQUIRED TO CREATE A NEW AFFILIATE
                                                onChange={handleChange}
                                                onBlur={() => {
                                                    if (!formData.softwareType) {
                                                        setSoftwareTypeError('Por favor, selecione um tipo de software.');
                                                    } else {
                                                        setSoftwareTypeError('');
                                                    }
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="softwareType-error"
                                            >
                                                <option disabled value="">
                                                    Seleciona a opção
                                                </option>
                                                <option value="NA">N/A</option>
                                                <option value="MAGENTO">MAGENTO</option>
                                                <option value="SHOPIFY">SHOPIFY</option>
                                                <option value="WORDPRESS">WORDPRESS</option>
                                                <option value="PRESTASHOP">PRESTASHOP</option>
                                                <option value="API">API</option>
                                            </select>
                                            {softwareTypeError && (
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {softwareTypeError && <p className="ml-1 mt-1 text-sm text-red-500">{softwareTypeError}</p>}
                                    </div>


                                    <div className="sm:col-span-6">
                                        <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                                            Website
                                        </label>
                                        <div className="mt-0.5">
                                            <div className="relative rounded-md shadow-sm">
                                             
                                                <input
                                                    type="text"
                                                    name="url"
                                                    id="url"
                                                    autoComplete="url"
                                                    value={formData.url}
                                                    onChange={handleChange}
                                                    className={`block w-full rounded-md border-gray-300  text-black focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                    onBlur={() => {
                                                        if (!formData.url) {
                                                            setUrlError('Por favor, coloque o url do seu website.'); // Display error if input is empty and form is submitted
                                                        } else {
                                                            setUrlError(false);
                                                        }
                                                    }}
                                                    aria-invalid="true"
                                                    aria-describedby="url-error"
                                                />
                                            {/*     {urlError && (
                                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                    </div>
                                                )} */}
                                            </div>
{/*                                             {urlError && <p className="ml-1 mt-1 text-sm text-red-500">{urlError}</p>}
 */}                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="adress" className="block text-sm font-medium text-gray-700">
                                            Morada
                                        </label>
                                        <div className="mt-1 relative">
                                            <input
                                                type="text"
                                                name="adress"
                                                id="adress"
                                                autoComplete="adress"
                                                value={formData.adress}
                                                onChange={handleChange}
                                                className={`block w-full rounded-md ${adressError ? 'border-red-300' : 'border-gray-300'} pr-3 text-black placeholder-red-300 focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                onBlur={() => {
                                                    setAddressError(false);
                                                    if (!formData.adress) handleChange({ target: { name: 'adress', value: '' } });
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="adress-error"
                                            />
                                            {adressError && (
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {adressError && <p className="ml-1 text-sm text-red-500">{adressError}</p>}
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700">
                                            Código Postal
                                        </label>
                                        <div className="mt-1 relative">
                                            <input
                                                type="text"
                                                value={formData.zipCode}
                                                onChange={handleChange}
                                                name="zipCode"
                                                id="zipCode"
                                                autoComplete="zipCode"
                                                className={`block w-full rounded-md ${zipCodeError ? 'border-red-300' : 'border-gray-300'} pr-3 text-black placeholder-red-300 focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                onBlur={() => {
                                                    setZipCodeError(false);
                                                    if (!formData.zipCode) handleChange({ target: { name: 'zipCode', value: '' } });
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="zipCode-error"
                                            />
                                            {zipCodeError && (
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {zipCodeError && <p className="ml-1 mt-1 text-sm text-red-500">{zipCodeError}</p>}
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="bicSwift" className="block text-sm font-medium text-gray-700">
                                            BIC/SWIFT
                                        </label>
                                        <div className="mt-1 relative">
                                            <input
                                                type="text"
                                                value={formData.bicSwift}
                                                onChange={handleChange}
                                                name="bicSwift"
                                                id="bicSwift"
                                                autoComplete="bicSwift"
                                                className={`block w-full rounded-md ${bicSwiftError ? 'border-red-300' : 'border-gray-300'} pr-3 text-black placeholder-red-300 focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                onBlur={() => {
                                                    setBicSwiftError(false);
                                                    if (!formData.bicSwift) {
                                                        setBicSwiftError('Por favor, coloque o BIC/SWIFT.');
                                                    }
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="bicSwift-error"
                                            />
                                            {bicSwiftError && (
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {bicSwiftError && <p className="ml-1 mt-1 text-sm text-red-500">{bicSwiftError}</p>}
                                    </div>
                                    <div className="sm:col-span-4">

                                        <label htmlFor="iban" className="block text-sm font-medium text-gray-700">
                                            IBAN
                                        </label>
                                        <div className="mt-0.5 relative">
                                            <input
                                                type="text"
                                                value={formData.iban}
                                                onChange={handleChange}
                                                name="iban"
                                                id="iban"
                                                autoComplete="family-name"
                                                className={`block w-full rounded-md ${ibanError ? 'border-red-300' : 'border-gray-300'}  pr-3 text-black placeholder-red-300 focus:border-green-900 focus:outline-none focus:ring-green-cl sm:text-sm`}
                                                onBlur={() => {
                                                    setIbanError(false);
                                                    if (!formData.iban) handleChange({ target: { name: 'iban', value: '' } });
                                                }}
                                                aria-invalid="true"
                                                aria-describedby="iban-error"
                                            />
                                            {ibanError && (
                                                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                                </div>
                                            )}
                                        </div>
                                        {ibanError && <p className="ml-1 mt-1 text-sm text-red-500">{ibanError}</p>}
                                    </div>


                                    <div className="sm:col-span-6">
                                        <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                                            Notes
                                        </label>
                                        <div className="mt-1">
                                            <textarea
                                                id="notes"
                                                name="notes"
                                                type="text"
                                                rows={3}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                value={formData.notes}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <p className="mt-2 text-sm text-gray-500">Escreve algumas informações extra sobre o afiliado</p>
                                    </div>
                                </div>

                                <div className="pt-5 pb-10 flex justify-end ">
                                    <Link to={'/admin/afiliados'}>

                                        <button
                                            type="button"
                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-offset-2"
                                        >
                                            Cancelar
                                        </button>
                                    </Link>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-cl py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-bg-green-700 focus:ring-offset-2"
                                        onClick={handleSubmit}
                                    >
                                        Guardar
                                    </button>

                                </div>
                            </div>                    </div>

                    </div>
                </form>

                <div className="relative ml-auto">

                </div>
            </div >
        </div >
    )
}


