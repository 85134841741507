import React from 'react';

const PasswordStrengthIndicator = ({ password }) => {
  const calculatePasswordStrength = () => {
    const passwordLength = password.length;
    if (passwordLength < 6) {
      return 'Fraca';
    } else if (passwordLength < 10) {
      return 'Média';
    } else {
      return 'Forte';
    }
  };

  const strength = calculatePasswordStrength();

  const getStrengthColor = () => {
    if (strength === 'Fraca') {
      return 'text-red-500';
    } else if (strength === 'Média') {
      return 'text-yellow-500';
    } else {
      return 'text-green-500';
    }
  };

  const getStrengthBarColor = () => {
    if (strength === 'Fraca') {
      return 'bg-red-500';
    } else if (strength === 'Média') {
      return 'bg-yellow-500';
    } else {
      return 'bg-green-500';
    }
  };

  const calculateStrengthBarWidth = () => {
    const maxStrengthLength = 10;
    if (password.length === 0) {
      return '0%';
    } else if (password.length >= maxStrengthLength) {
      return '100%';
    } else if (strength === 'Fraca') {
      return '33%';
    } else if (strength === 'Média') {
      return '66%';
    } else {
      return '100%';
    }
  };

  const strengthBarClassName = getStrengthBarColor();
  const strengthBarWidth = calculateStrengthBarWidth();

  return (
    <div className="mt-4">
      <span className={`text-sm font-semibold ${getStrengthColor()}`}>
        {strength}
      </span>
      <div className="mt-2 h-2 bg-gray-200 rounded-full">
        <div className={`h-full ${strengthBarClassName}`} style={{ width: strengthBarWidth }}></div>
      </div>
    </div>
  );
};

export default PasswordStrengthIndicator;