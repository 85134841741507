import React, { createContext, useState } from 'react';
import { useMemo } from 'react';
export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [user, setUser] = useState({
    email: '',
    password: '',
    error: '',
    rememberMe: false,
    showPassword: false,
  });

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;