

import { ClockIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { useAffiliateData } from '../../components/hooks/getAffiliates';
import TabsUser from '../../components/tabs/TabsUser';
import Logout from '../login/Logout';
import EachBalance from './EachBalance';
export const EachPartner = () => {
    const { id } = useParams();

    const pages = [
        { name: 'Lista de Afiliados', href: '/admin/afiliados', current: false },

        { name: `Editar Afiliado`, href: `/admin/afiliados/${id}/edit`, current: true },
    ];

    const jwtToken = sessionStorage.getItem('jwt');
    const data = useAffiliateData(id, jwtToken);


    return (
        <>

            <div className="ml-10 mr-10 mt-2">
                <div className="flex justify-between">
                    <Breadcrumb pages={pages} />
                    {/* <div className="flex justify-end sm:flex-row mt-8 sm:items-center sm:space-x-4">
   <Logout />
  </div> */}
                </div>
                <div className="flex items-center mt-10">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-black uppercase text-green-cl ">{data.name} </h1>
                        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8 justify-between">
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <ClockIcon className="mr-1.5 w-4 flex-shrink-0 text-green-cl text-bold" aria-hidden="true" />
                                <div className='text-gray-900 font-100'>Registado em {data.createAt}</div>
                            </div>
                        </div>
                    </div>
                    <EachBalance />

                </div>
            </div>

            <TabsUser></TabsUser>
        </>
    )
}
