import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { URL } from '../../service/Service';
import RateModal from './RateModal';
import RateModalTax from './RateModalTax'; // Import the RateModalTax component

const ConfirmationModal = ({ rate, onDelete, onCancel }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-75 bg-gray-900">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <p className="text-xl font-bold mb-4">
          Tem a certeza que deseja apagar esta taxa {rate.nameService}?
        </p>
        <div className="text-md font-medium -mt-4">
          Esta funcionalidade é irreversível.{' '}
          <p>Terá que criar nova taxa para este método de pagamento.</p>
        </div>

        <div className="flex justify-end mt-4">
          <button
            className="bg-green-cl text-white px-4 py-2 rounded mr-2"
            onClick={() => onDelete(rate)}
          >
            Apagar
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

const NewRates = () => {
  const [rates, setRates] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);

  useEffect(() => {
    const getAffiliateRates = async () => {
      try {
        const jwtToken = sessionStorage.getItem('jwt');
        const res = await axios.get(`${URL}/api/affiliates/${id}/rates`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        setRates(res.data);
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    getAffiliateRates();
  }, [id]);

  const handleDelete = (rate) => {
    setSelectedRate(rate);
    setShowModal(true);
  };

  const handleConfirmDelete = async (rate) => {
    try {
      const jwtToken = sessionStorage.getItem('jwt');
      await axios.delete(
        `${URL}/api/affiliates/${id}/rate/${rate.nameService}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setRates((prevRates) =>
        prevRates.filter((r) => r.nameService !== rate.nameService)
      );
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md p-4 mb-10">
      <div className="flex items-center justify-between px-6 p-8">
        <div>
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Taxas atualmente ativas
          </h1>
          <p className="text-sm text-gray-500">
            Verifica as taxas atualmente ativas, podendo também criar ou
            alterá-las se já estiverem criadas.
          </p>
        </div>
        
      </div>

      <div className="grid px-6 mb-14 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
        {rates.map((rate) => (
          <div
            key={rate.nameService}
            className="relative bg-gray-50 rounded-lg shadow-sm overflow-hidden transition-colors border-2 border-opacity-10 border-green-cl duration-300 hover:bg-gray-100 cursor-pointer"
          >
            <div className="absolute top-0 right-0 mt-2 mr-2">
              <button
                className="text-gray-500 hover:text-black"
                onClick={() => handleDelete(rate)}
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-5"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                </svg>
              </button>
            </div>
            <div className="px-4 py-6">
              <div className="flex items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                {rate.nameService === "CreditCard" ? "Cartão de Crédito" : rate.nameService === "DirectDebit" ? "Débito direto" : rate.nameService === "CredoraxTPA" ? "Credorax TPA" : rate.nameService}
                </h3>
              </div>
              <div className="text-sm font-bold text-green-cl mb-2">
                {rate.rateType === 'FIXED_RATE' && 'Taxa Fixa'}
                {rate.rateType === 'VARIABLE_RATE' && 'Taxa Variável'}
                {rate.rateType === 'MIXED_RATE' && 'Taxa Mista (Fixa e Variável)'}
              </div>
              <div className="flex items-center text-2xl font-bold text-green-900">
                {rate.rateType === 'FIXED_RATE' && (
                  <>
                    {rate.rateValue}
                    <span className="mr-1">€</span>
                  </>
                )}
                {rate.rateType === 'VARIABLE_RATE' && <>{rate.ratePercentage}%</>}
                {rate.rateType === 'MIXED_RATE' && (
                  <>
                    <span className="mr-1">€</span>
                    {rate.rateValue}
                    <span className="mx-1">+</span>
                    {rate.ratePercentage}%
                  </>
                )}
              </div>
            </div>
          </div>
        ))}

        {/* Conditionally render the appropriate modal based on rates length */}
        {rates.length === 0 ? (
          <RateModal />
        ) : (
          <RateModalTax />
        )}
      </div>
      {showModal && (
        <ConfirmationModal
          rate={selectedRate}
          onDelete={handleConfirmDelete}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default NewRates;
