import React from 'react'
import WithdrawalForm from './WithdrawalForm'

export const Withdrawal = () => {
    return (
        <>
        <WithdrawalForm />
        </>
    )
}
