import { useState, useEffect } from 'react';
import { URL } from '../../service/Service';
export function useFetchAdmin(jwt) {
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    const jwtToken = sessionStorage.getItem("jwt");
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${jwtToken}`);

    fetch(`${URL}/api/getAdmin`, { headers: headers })
      .then(res => res.json())
      .then(data => {
        setAdmin(data);
      });
  }, [jwt]);

  return admin;
}