import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'

const Breadcrumb = ({ pages }) => (
  <nav className="flex mt-11" aria-label="Breadcrumb">
    <ol className="flex items-center space-x-4">
      <li>
        <div>
          <a href="/admin/dashboard" className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="h-5 w-5 flex-shrink-0 text-green-cl" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </a>
        </div>
      </li>
      {pages.map(({ name, href, current }) => (
        <li key={name} className="flex items-center">
          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          <a
            href={href}
            className={`ml-4 text-sm ${current ? 'font-bold text-green-cl' : 'font-medium'} text-gray-500 hover:text-gray-700`}
            aria-current={current ? 'page' : undefined}
          >
            {name}
          </a>
        </li>
      ))}
    </ol>
  </nav>
)

export default Breadcrumb
