
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import { useParams } from 'react-router-dom';
import { useAffiliateData } from '../../components/hooks/getAffiliates';
import ClientModal from './ClientModal';
import { PageHeader2 } from './PageHeader2';
import { TableClients } from './TableClients';
export const Client = () => {
  const { id } = useParams();


  const icon = (<CursorArrowRaysIcon></CursorArrowRaysIcon>)
  const button1 = (
    <ClientModal />
  );

/*   const button2 = (

    <Link to={`/admin/afiliados/${id}/taxas`}>
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-cl px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-eupago focus:outline-none focus:ring-2 focus:ring-green-cl focus:ring-offset-2 sm:w-auto"
      >
        Definir Taxas
      </button>
    </Link>
  ); */
  const jwtToken = sessionStorage.getItem('jwt');
  const data = useAffiliateData(id, jwtToken);


  return (
    <>
      <PageHeader2 icons={icon} button1={button1} />
      <TableClients />
    </>
  )
}
export default Client