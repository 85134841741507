
export const PageHeader2 = ({ title, subtitle, button1, button2 }) => {
    return (
        <div className="mx-auto flex max-w-4xl flex-col md:px-8 xl:px-0">
            <div className="py-6 md:items-center md:justify-between ">
                <div className="px-4 sm:px-6 md:px-0">
                    <div className="sm:flex sm:items-center mt-4">
                        <div className="sm:flex-auto">
                            <h1 className="text-md font-bold uppercase text-green-cl ">{title}</h1>
                            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
                                <div className="flex items-center text-sm text-gray-500">
                                 {/*    <div className="mr-1.5 w-4 flex-shrink-0 text-green-cl text-bold" aria-hidden="true"> {icons} </div> */}
                                    <div className='text-gray-900 font-100'>{subtitle}</div>
                                </div>
                            </div>
                        </div>
                            <div className="mt-4 inline-flex">
                                {button1}
                                {button2}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}