import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';

export default function SuccessMessage({ message }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timeoutId);
  }, []);

  if (!message) {
    return null;
  }

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed top-0 inset-x-0 p-4 bg-green-500 text-white text-center">
        {message}
      </div>
    </Transition>
  );
}
