import { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Error404 } from "./components/error/Error404";
import UserInfo from "./components/tabs/UserInfo";
import AccountSettings from "./components/tabsAdminInfo/AccountSettings";
import TableAdmin from "./components/tabsAdminManager/TableAdmin";
import Client from "./scenes/clients/Client";
import Login from "./scenes/login/Login";
import Logout from "./scenes/login/Logout";
import ResetPassword from "./scenes/login/ResetPassword";
import Create from "./scenes/partner/Create";
import Dashboard from "./scenes/partner/Dashboard";
import { EachPartner } from "./scenes/partner/EachPartner";
import { TablePartner } from "./scenes/partner/TablePartner";
import Sb from "./scenes/sidebar/Sb";
import useAuthentication from "./scenes/useAuthentication";
import DashboardUser from "./userScenes/DashboardUser";
import ActiveRates from "./userScenes/clients/ActiveRatesUser";
import { Balance } from "./userScenes/clients/Balance";
import { Table } from "./userScenes/clients/Table";
import OficialSidebar from "./userScenes/sidebarUser/OficialSidebar";
import ResetPasswordForm from "./scenes/login/ResetPasswordForm";
import Notifications from "./scenes/notifications/Notifications";
import NotificationsAffiliate from "./scenes/notifications/NotificationsAffiliate";

function App() {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);

  const toggleSidebar = () => {
    setOpen(!open);
  };

  useAuthentication();

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isLoginPage = location.pathname === "/" || location.pathname.includes("/resetpassword");
  const isUserPath = location.pathname.startsWith("/user");
  const isAdminPath = location.pathname.startsWith("/admin");

  const className = isLoginPage ? "" : (open ? "ml-64" : "ml-20");

  return (
    <div className="App">
      {!isLoginPage && (
        <>
          {isUserPath && (
            <OficialSidebar
              sidebarOpens={sidebarOpen}
              setSidebarOpens={setSidebarOpen}
              opens={open}
              onToggles={toggleSidebar}
            />
          )}
          {isAdminPath && (
            <Sb
              sidebarOpens={sidebarOpen}
              setSidebarOpens={setSidebarOpen}
              opens={open}
              onToggles={toggleSidebar}
            />
          )}
        </>
      )}

      <div className={className}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPasswordForm />} />
          <Route path="/admin/afiliados" element={<TablePartner />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/admins" element={<TableAdmin />} />
          <Route path="/admin/notifications" element={<Notifications />} />
          <Route path="/user/notifications" element={<NotificationsAffiliate />} />
          <Route path="/admin/error" element={<Error404 />} />
          <Route path="/admin/geral" element={<AccountSettings />} />
          <Route path="/admin/criar_afiliado" element={<Create />} />
          <Route path="/admin/afiliados/:id/edit" element={<EachPartner />} />
          <Route path="/admin/afiliados/:id/clientes" element={<Client />} />
          <Route path="/user/geral" element={<UserInfo />} />
          <Route path="/user/dashboard" element={<DashboardUser />} />
          <Route path="/user/clientes" element={<Table />} />
          <Route path="/user/levantamento" element={<Balance />} />
          <Route path="/user/taxas" element={<ActiveRates />} />
          <Route path="/admin/logout" element={<Logout />} />
          {/* Redirect any unknown routes to Error404 */}
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
