/* This example requires Tailwind CSS v2.0+ */


export default function WithdrawalCancel({ person, updateHandler }) {


    return (

        <div className="text-left mr-24 min-w-full">
            <h1 className="text-xl font-bold mb-4">PEDIDO DE CANCELAMENTO DE LEVANTAMENTO</h1>
            <p className="text-md -mt-4">Tens a certeza que pretendes cancelar o pedido de levantamento? </p>

            <button className="w-full mt-6 bg-orange-700 hover:bg-green-900 text-white font-semibold focus:ring-4 py-2 px-4 rounded-lg"
                onClick={(e) => updateHandler(person, person.status, e)}>
                SIM, TENHO A CERTEZA
            </button>
            <p className="mt-4 font-sm">
            <small><strong>ID DE PEDIDO</strong> {person.recordID}</small>
            </p>
        </div>

    )
}