import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Pagination from "../../components/pagination/Pagination";
import DeleteModalAdmin from "./DeleteModalAdmin";
import CreateAdminModal from "./CreateAdminModal";
import { URL } from "../../service/Service";

export const TableAdmin = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [originalPeople, setOriginalPeople] = useState([]);
  const [peopleData, setPeopleData] = useState(originalPeople);
  const [setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [userRole, setUserRole] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(
    "Selecione o tipo de conta"
  );
  const pages = [
    {
      name: "Lista de Administradores e Gestores",
      href: "/admin/admins",
      current: true,
    },
  ];

  const jwtToken = sessionStorage.getItem("jwt");

  useEffect(() => {
    const storedUserRole = sessionStorage.getItem("userRole");
    if (storedUserRole) {
      setUserRole(storedUserRole.toUpperCase());
    } else {
      setUserRole("MANAGER"); // Default role
      console.warn("User role not found, defaulting to MANAGER.");
    }
    refreshAdmins(); // Fetch admins when the component mounts
  }, []);

  // Refresh function to reload the list of admins
  const refreshAdmins = async () => {
    const jwtToken = sessionStorage.getItem("jwt");
    try {
      const res = await axios.get(`${URL}/api/findAll/admin/manager`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setOriginalPeople(res.data);
      setPeopleData(res.data);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setPlaceholderText("Selecione...");
      } else if (window.innerWidth < 700) {
        setPlaceholderText("Selecione o tipo...");
      } else {
        setPlaceholderText("Selecione o tipo de conta");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = peopleData.slice(indexOfFirstPost, indexOfLastPost);

  const handleChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    applyFilters(searchTerm, roleFilter);
  };

  const handleRoleFilterChange = (event) => {
    const selectedRole = event.target.value;
    setRoleFilter(selectedRole);
    applyFilters(searchTerm, selectedRole);
  };

  const applyFilters = (searchTerm, selectedRole) => {
    let filteredData = originalPeople;
    if (searchTerm) {
      filteredData = originalPeople.filter(
        (data) =>
          data.name.toLowerCase().includes(searchTerm) ||
          data.email.toLowerCase().includes(searchTerm)
      );
    }

    if (selectedRole) {
      filteredData = filteredData.filter((data) => data.role === selectedRole);
    }

    setPeopleData(filteredData);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${URL}/api/delete/user/${id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      return true; // Indicate success without refreshing immediately
    } catch (error) {
      console.error(error);
      setError(error);
      return false; // Return failure
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleModalOpen = () => {
    console.log("Opening modal with userRole:", userRole);
    setIsModalOpen(true);
  };
  const handleModalClose = () => setIsModalOpen(false); // Close the modal

  return (
    <Container fluid className="mt-6 mx-4 sm:mx-10">
      <Row className="justify-between items-center mb-6">
        <Col>
          <Breadcrumb pages={pages} />
        </Col>
      </Row>

      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <div className="text-center sm:text-left">
          <h1 className="text-2xl sm:text-3xl font-black text-gray-900">
            ADMINISTRADORES E GESTORES
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Lista de todos os administradores e gestores, com detalhes como
            nome, email, e tipo de conta.
          </p>
        </div>
        <Button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-green-cl rounded-md shadow-sm hover:bg-green-eupago focus:outline-none focus:ring-2 focus:ring-green-cl focus:ring-offset-2"
          onClick={handleModalOpen}
        >
          {userRole.toUpperCase() === "ADMIN"
            ? "Novo Admin/Gestor"
            : "Novo Gestor"}
        </Button>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 items-center mb-6 w-full">
        <input
          type="text"
          placeholder="Pesquisar por Nome ou Email"
          className="w-full sm:flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200 focus:border-indigo-500"
          onChange={handleChange}
          value={searchTerm}
        />
        <select
          value={roleFilter}
          onChange={handleRoleFilterChange}
          className="w-full sm:w-56 px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-200 focus:border-indigo-500"
        >
          <option value="" disabled hidden>
            {placeholderText}
          </option>
          <option value="">Todos</option>
          <option value="ADMIN">Admin</option>
          <option value="MANAGER">Gestor</option>
        </select>
      </div>

      <Row className="rounded-lg ">
        <Col>
          <div className="overflow">
            <table className="w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-xs font-semibold text-gray-900 text-left">
                    <span className="sr-only">Delete</span>
                  </th>
                  <th className="px-4 py-3 text-xs font-semibold text-gray-900 text-left">
                    Nome
                  </th>
                  <th className="px-4 py-3 text-xs font-semibold text-gray-900 text-left">
                    Email
                  </th>
                  <th className="px-4 py-3 text-xs font-semibold text-gray-900 text-left">
                    Tipo de Conta
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-xs">
                {currentPosts.map((person) => (
                  <tr key={person.id}>
                    <td className="px-2 py-3 whitespace-nowrap">
                      <DeleteModalAdmin
                        userRole={userRole}
                        targetRole={person.role}
                        userId={person.id}
                        handleDelete={handleDelete}
                        refreshAdminsAfterClose={refreshAdmins}
                        disabled={
                          userRole === "MANAGER" && person.role === "ADMIN"
                        }
                      />
                    </td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm">
                      {person.name}
                    </td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm">
                      {person.email}
                    </td>
                    <td className="px-2 py-3 whitespace-nowrap text-sm">
                      {person.role === "ADMIN" ? "Administrador" : "Gestor"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Row className="mt-6">
        <Col>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={peopleData.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Col>
      </Row>
      <CreateAdminModal
        show={isModalOpen}
        handleClose={handleModalClose}
        userRole={userRole}
        refreshAdmins={refreshAdmins}
      />
    </Container>
  );
};

export default TableAdmin;
