import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faSpinner}  className="animate-spin h-5 w-5 mr-2 text-white"/>
      <span className="text-white">Está quase...</span>
    </div>
  );
};

export default LoadingSpinner;
