import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL } from "../../service/Service";

const EditClientModalUser = ({ customerID, affiliateID, onClose }) => {
  console.log("AffiliateID in Modal:", affiliateID); // Verifique o valor do affiliateID

  const [formData, setFormData] = useState({
    name: "",
    site: "",
    notes: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const jwtToken = sessionStorage.getItem("jwt");

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const res = await axios.get(
          `${URL}/api/affiliates/${affiliateID}/customer/${customerID}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        setFormData(res.data);
        setLoading(false);
      } catch (error) {
        let errorMessage = error.message;
        console.error(
          "Error fetching client data:",
          error.response?.status,
          error.response?.data || error.message
        );
        if (error.message === "Failed to fetch") {
          errorMessage =
            "There was a problem updating the client. Please try again later.";
        }
        setLoading(false);
      }
    };

    if (affiliateID && customerID) {
      console.log(
        `Fetching from: ${URL}/api/affiliates/${affiliateID}/costumer/${customerID}/`
      );
      fetchClientData();
    }
  }, [affiliateID, customerID, jwtToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error messages
    setError(null);
    setSuccess(null);
    setLoading(true);
    try {
      await axios.patch(
        `${URL}/api/affiliates/${affiliateID}/customer/${customerID}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setSuccess("Client updated successfully");
    } catch (error) {
      // Determine the error message based on error type
      let errorMessage = error.response?.data || error.message;

      // Check for network-related errors
      if (error.code === "ERR_NETWORK" || error.message === "Network Error") {
        errorMessage =
          "There was a problem updating the client. Please try again later.";
      }

      console.error("Error updating client:", errorMessage);
      setError(errorMessage); // Set the error message to the state for the UI
    } finally {
      setLoading(false);
    }
  };

  const handleOutsideClick = (e) => {
    // Check if the click is outside the modal content
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50 modal-overlay"
      onClick={handleOutsideClick}
    >
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto"
        onClick={(e) => e.stopPropagation()} // Stops propagation
      >
        {loading ? (
          <div className="flex items-center justify-center p-6">
            <svg
              className="animate-spin h-5 w-5 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 4.5V1.5M12 22.5v-3M4.5 12H1.5M22.5 12h-3M3.4 3.4l2.1 2.1M18.5 18.5l2.1 2.1M3.4 20.6l2.1-2.1M18.5 5.5l2.1-2.1"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        ) : (
          <>
            {error && <div className="p-4 text-red-600">{error}</div>}
            {success && <div className="p-4 text-green-600">{success}</div>}
            <form onSubmit={handleSubmit} className="p-6">
              <h2 className="text-xl font-bold mb-6">Editar Cliente</h2>
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 sm:text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="site"
                  className="block text-sm font-medium text-gray-700"
                >
                  Site
                </label>
                <input
                  type="text"
                  name="site"
                  id="site"
                  value={formData.site}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 sm:text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="notes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Notas
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  rows="4"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 sm:text-sm"
                />
              </div>
              <div className="flex gap-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                >
                  {loading ? "A guardar..." : "Guardar"}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default EditClientModalUser;
