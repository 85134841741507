import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import useFetchIDAffiliate from "./useFetchIDAffiliate";

function CountdownTimer({ timeUntilNextTrigger }) {
  const hours = String(
    Math.floor(timeUntilNextTrigger / (1000 * 60 * 60))
  ).padStart(2, "0");
  const minutes = Math.floor(
    (timeUntilNextTrigger % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = String(
    Math.floor((timeUntilNextTrigger % (1000 * 60)) / 1000)
  ).padStart(2, "0");
  const showText = timeUntilNextTrigger > 0;

  return (
    <div className="text-xs font-thin flex flex-col items-center">
      <div className="flex items-center space-x-1">
        <div className="font-bold">{hours}h</div>
        <span className="text-gray-400">:</span>
        <div className="font-bold">{minutes}m</div>
        <span className="text-gray-400">:</span>
        <div className="font-bold">{seconds}s</div>
      </div>
      {showText && <div className="text-gray-500 text-xs">até novo pedido</div>}
    </div>
  );
}
function AccountBalance() {
  const [showBalance, setShowBalance] = useState(false);
  const [balanceData, setBalanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [timeUntilNextTrigger, setTimeUntilNextTrigger] = useState(0);
  const [lastRecordTimestamp, setLastRecordTimestamp] = useState("");
  const [buttonHovered, setButtonHovered] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [canHover, setCanHover] = useState(false);

  const jwtToken = sessionStorage.getItem("jwt");
  const { loading: loadingAffiliateID, affiliateID } = useFetchIDAffiliate(jwtToken);

  useEffect(() => {
    if (loadingAffiliateID || !affiliateID) return;

    const fetchBalanceData = async () => {
      setLoading(true);
      try {
        const URL = require("../service/Service").URL;
        const res = await fetch(`${URL}/api/affiliates/${affiliateID}/balance`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        });

        if (!res.ok) {
          throw new Error('Failed to fetch balance data');
        }

        const data = await res.json();
        setBalanceData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalanceData();
  }, [affiliateID, jwtToken, loadingAffiliateID]);

  useEffect(() => {
    if (loadingAffiliateID || !affiliateID) return;

    const fetchTimestamp = async () => {
      try {
        const URL = require("../service/Service").URL;
        const response = await fetch(`${URL}/api/${affiliateID}/lastRecordTimestamp`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch last record timestamp");
        }

        const timestampData = await response.text();
        setLastRecordTimestamp(timestampData);
      } catch (error) {
        console.error("Error fetching last record timestamp:", error.message);
      }
    };

    fetchTimestamp();
  }, [affiliateID, jwtToken, loadingAffiliateID]);

  useEffect(() => {
    const updateCountdown = () => {
      if (!lastRecordTimestamp) return;

      const SIX_HOURS_IN_MILLISECONDS = 6 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const timestamp = new Date(lastRecordTimestamp).getTime();
      const timeDifference = currentTime - timestamp;

      const timeUntilNextTrigger = SIX_HOURS_IN_MILLISECONDS - timeDifference;
      setTimeUntilNextTrigger(
        timeUntilNextTrigger > 0 ? timeUntilNextTrigger : 0
      );

      setButtonDisabled(timeUntilNextTrigger > 0);
      setShowCountdown(timeUntilNextTrigger > 0);
    };

    const interval = setInterval(updateCountdown, 1000);
    setShowCountdown(false);

    return () => clearInterval(interval);
  }, [lastRecordTimestamp]);

  useEffect(() => {
    setTimeout(() => setCanHover(true), 2000);
  }, []);

  const handleToggle = () => {
    setShowBalance(!showBalance);
  };

  const triggerTotalCalculationAffiliate = async () => {
    try {
      if (!affiliateID || !jwtToken) return;

      const URL = require("../service/Service").URL;
      const response = await fetch(`${URL}/api/${affiliateID}/triggerTotalCalculation`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        console.log("Total amount calculation triggered successfully.");
        setButtonDisabled(true);
        setTimeUntilNextTrigger(6 * 60 * 60 * 1000);
        window.location.reload();
      } else {
        throw new Error("Failed to trigger total amount calculation.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center space-y-4 mt-8 sm:flex-row sm:justify-end sm:items-center sm:space-y-0 sm:space-x-4">
      <div className="relative inline-block text-center">
        <button
          type="button"
          className={`w-full sm:w-auto rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-cl ${
            showBalance ? "flex justify-between" : "flex items-center"
          }`}
          onClick={handleToggle}
        >
          {showBalance ? (
            <>
              <span className="font-bold">{(Math.ceil(balanceData?.balance * 100) / 100).toFixed(2).replace('.', ',')}€</span>
              <span className="hidden sm:block ml-2 font-light text-gray-400 text-xs">
                (Clica para esconder)
              </span>
            </>
          ) : (
            <>
              <div className="text-green-cl font-bold">Saldo da Conta</div>
              <span className="hidden sm:block ml-2 font-light text-gray-400 text-xs">
                (Clica para mostrar)
              </span>
            </>
          )}
        </button>
      </div>

      <button
        className="bg-white rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 flex items-center justify-center transition duration-300"
        onClick={() => window.location.href = "/user/levantamento"}
      >
        <FontAwesomeIcon
          icon={faMoneyBillTransfer}
          className="mr-2 text-green-cl"
        />
        Ver transações
      </button>

      <button
        onClick={
          buttonDisabled || timeUntilNextTrigger > 0
            ? null
            : triggerTotalCalculationAffiliate
        }
        className={`relative inline-flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-cl ${
          buttonDisabled || timeUntilNextTrigger > 0
            ? "bg-gray-300 text-gray-700 cursor-not-allowed"
            : "bg-green-cl text-white hover:bg-green-cl hover:text-white"
        }`}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
      >
        <div className="relative">
          Atualizar saldo
          {buttonHovered && showCountdown && (
            <div className="absolute top-full left-0 right-0 z-10 mt-2 sm:mt-4">
              <CountdownTimer timeUntilNextTrigger={timeUntilNextTrigger} />
            </div>
          )}
        </div>
      </button>
    </div>
  );
}

export default AccountBalance;